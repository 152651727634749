import { useContext } from 'react';
import * as Realm from 'realm-web';
import { MdCheckCircle } from 'react-icons/md';
import { AlertContext } from '../contexts/AlertContext';
import { ClipsContext } from '../contexts/ClipsContext';
import { ModalContext } from '../contexts/ModalContext';
import { PlaylistsContext } from '../contexts/PlaylistsContext';
import ConfirmModal from '../components/modals/ConfirmModal';
import EditModal from '../components/modals/EditModal';
import useAmplitude from './useAmplitude';
import { APP_ID } from '../../constants';

const realm = Realm.App.getApp(APP_ID);

const usePlaylistActions = () => {
  const { dispatch: dispatchAlert } = useContext(AlertContext);
  const {
    state: { playlist },
    dispatch: dispatchClips,
  } = useContext(ClipsContext);
  const { dispatch: dispatchModal } = useContext(ModalContext);
  const { dispatch: dispatchPlaylists } = useContext(PlaylistsContext);

  const { trackEvent } = useAmplitude();

  // ----- STAR Playlist -----
  const starPlaylist = async (id, isStarred) => {
    await realm.currentUser.functions.updatePlaylistStarred(id, isStarred);

    // if this playlist is active, update it
    if (playlist?._id.toString() === id) {
      dispatchClips({
        type: 'UPDATE_PLAYLIST',
        data: {
          starred: isStarred,
        },
      });
    }

    // update list of playlists
    fetchPlaylists();
  };

  // ----- FETCH Playlists -----

  const fetchPlaylists = async () => {
    const results = await realm.currentUser.functions.getPlaylists();

    const filtered = {
      starred: [],
      playlists: [],
    };

    results.map((p) => {
      if (p.starred) filtered.starred.push(p);
      else filtered.playlists.push(p);
    });

    dispatchPlaylists({
      type: 'SET_PLAYLISTS',
      data: filtered,
    });
  };

  // ----- LOAD Playlist -----

  const loadPlaylist = async (id) => {
    try {
      const playlist = await realm.currentUser.functions.getPlaylistInfo(id);

      dispatchClips({
        type: 'SET_LIST',
        data: {
          title: playlist.title,
          count: playlist.clips.length,
          list: playlist.clips,
          type: 'playlist',
          playlist: playlist,
        },
      });
    } catch (err) {
      console.log('Playlist could not be loaded: ', err);
    }
  };

  // ----- EDIT Playlist -----

  const editPlaylist = (onFinish) => {
    dispatchModal({
      type: 'ADD_MODAL',
      data: {
        component: EditModal,
        props: {
          config: playlist,
          isPlaylist: true,
          maxWidth: '600px',
          borderRadius: '20px',
        },
        onDismiss: typeof onFinish === 'function' ? onFinish : null,
      },
    });
  };

  // ----- CREATE Playlist -----

  const createPlaylist = async (formData, onFinish) => {
    try {
      await realm.currentUser.functions.createPlaylist(formData);

      // log event in Amplitude
      trackEvent('Playlist Created');

      // update list of playlists anywhere they display
      fetchPlaylists();

      onFinish();
    } catch (err) {
      throw err;
    }
  };

  // ----- UPDATE Playlist -----

  const updatePlaylist = async (id, formData, changeLog, onFinish) => {
    try {
      await realm.currentUser.functions.updatePlaylistInfo(id, formData);

      // log change event in Amplitude
      trackEvent('Playlist Edited', {
        'Playlist ID': id,
        'Title Changed': changeLog.title ? 'Yes' : 'No',
        'Description Changed': changeLog.description ? 'Yes' : 'No',
      });

      // if this is active playlist, update it in state to display latest info
      if (id === playlist._id.toString()) {
        dispatchClips({
          type: 'UPDATE_PLAYLIST',
          data: {
            ...formData,
          },
        });
      }

      // show success to user
      dispatchAlert({
        type: 'ADD_ALERT',
        data: {
          message: 'Playlist Updated',
          type: 'toast',
          icon: MdCheckCircle,
        },
      });

      // update list of playlists anywhere they display
      fetchPlaylists();

      onFinish();
    } catch (err) {
      throw err;
    }
  };

  // ----- DELETE Playlist -----

  const deletePlaylist = () => {
    dispatchModal({
      type: 'ADD_MODAL',
      data: {
        component: ConfirmModal,
        onConfirm: confirmDelete,
        props: {
          title: 'Delete Playlist?',
          description: `This will delete the <b>${playlist?.title}</b> playlist. All clips will still exist in your library.`,
          confirmLabel: 'Delete',
          confirmIsDestructive: true,
          maxWidth: '480px',
          borderRadius: '20px',
        },
      },
    });
  };

  const confirmDelete = async () => {
    // track deletion in Amplitude
    trackEvent('Playlist Deleted', {
      'Playlist Clip Count': playlist.count,
    });

    // delete it
    await realm.currentUser.functions.deletePlaylist(playlist._id.toString());

    // refresh list
    if (playlist) {
      dispatchClips({
        type: 'CLEAR',
      });
    }

    fetchPlaylists();
  };

  return {
    createPlaylist,
    deletePlaylist,
    editPlaylist,
    starPlaylist,
    fetchPlaylists,
    loadPlaylist,
    updatePlaylist,
  };
};

export default usePlaylistActions;
