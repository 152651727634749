import * as Realm from 'realm-web';
import { APP_ID, CREATOR_URL, ENV } from '../../constants';

const getCreatorAPIKey = async () => {
  const realm = Realm.App.getApp(APP_ID);
  let key = realm.currentUser?.customData?.apiKey;

  if (!key) {
    // temporary to delete old keys (only for Chris and I)
    const keys = await realm.currentUser.apiKeys.fetchAll();
    if (keys[0]) await realm.currentUser.apiKeys.delete(keys[0].id);

    // create a new key and save it on the user record
    const keyRet = await realm.currentUser.apiKeys.create(realm.currentUser.id);
    key = keyRet.key;
    await realm.currentUser.functions.saveUserApiKey(key);
    // update custom data after -- moot: redirecting to new url
    await realm.currentUser.refreshCustomData();
  }

  return key;
};

export const openCreator = async () => {
  const key = await getCreatorAPIKey();
  const url = `${CREATOR_URL[ENV]}?key=${key}`;
  window.location = url;
};
