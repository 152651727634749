import React, { useState, memo, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';
import useOutsideClick from '../../hooks/useOutsideClick';

const ContextMenu = forwardRef(
  ({ options, onSelect, position, hasDelete, children }, ref) => {
    const [showMenu, setShowMenu] = useState(false);

    const outsideClickRef = useOutsideClick(() => {
      setShowMenu(false);
    });

    const onShowMenu = (e) => {
      // click of opener when already open should not show it again
      if (showMenu) return;

      // prevent bubbling of click event
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();

      // add dropdown to the DOM
      setShowMenu(true);
    };

    const itemSelected = (label) => {
      onSelect(label);
      setShowMenu(false);
    };

    useImperativeHandle(
      ref,
      () => {
        return {
          onHide() {
            setShowMenu(false);
          },
        };
      },
      [],
    );

    return (
      <>
        <BtnWrapper onClick={onShowMenu}>{children}</BtnWrapper>
        {showMenu ? (
          <Menu style={position ? { ...position } : null} ref={outsideClickRef}>
            <ul>
              {options.map((label, idx) =>
                label === 'separator' ? (
                  <Separator key={`separator-${idx}`} />
                ) : (
                  <ListItem
                    key={label}
                    onClick={() => itemSelected(label)}
                    isDelete={hasDelete && label.indexOf('Delete') > -1}>
                    {label}
                  </ListItem>
                ),
              )}
            </ul>
          </Menu>
        ) : null}
      </>
    );
  },
);

const BtnWrapper = styled.button`
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
`;

const Menu = styled.div`
  position: absolute;
  z-index: 1;
  top: 36px;
  left: -1px;
  box-shadow: 0 2px 8px 1px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(223, 227, 234, 0.85);
  border-radius: 4px;
  width: 200px;
  max-height: 280px;
  overflow-y: auto;
  padding: 2px;
  background-color: white;
  line-height: 22px;
  text-align: left;
`;

const ListItem = styled.li`
  display: block;
  font-size: 13px;
  width: 100%;
  padding: 6px 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    background-color: rgba(223, 227, 234, 0.4);
  }

  ${({ isDelete, theme }) =>
    isDelete &&
    `
    color: ${theme.colors.warning};
    
  `}
`;

const Separator = styled.li`
  border-top: 1px solid ${(props) => props.theme.colors.border};
`;

export default memo(ContextMenu);
