import React, { useState } from 'react';
import styled from 'styled-components';
import GridClip from './GridClip';
import GridClipMobile from './GridClipMobile';
import ActivityIndicator from '../common/ActivityIndicator';
import { IS_MOBILE } from '../../../constants';

const Grid = ({ data, onClipClick, onMenuClick }) => {
  const [playingId, setPlayingId] = useState(null);

  const newVideoPlaying = (id) => {
    // pass to all clips so only one video plays, cancel all others
    setPlayingId(id);
  };

  return (
    <Wrapper>
      <Group>
        <Container count={data?.length || 0}>
          {data ? (
            data.map((clip) =>
              IS_MOBILE ? (
                <GridClipMobile
                  key={clip._id.toString()}
                  config={clip}
                  onClipClick={onClipClick}
                  onMenuClick={onMenuClick}
                />
              ) : (
                <GridClip
                  key={clip._id.toString()}
                  config={clip}
                  onStartPlaying={newVideoPlaying}
                  onClipClick={onClipClick}
                  isPlaying={playingId === clip._id}
                />
              ),
            )
          ) : (
            <LoadingWrapper>
              <ActivityIndicator color="secondary" />
            </LoadingWrapper>
          )}
        </Container>
      </Group>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding-top: 2rem;

  @media only screen and (max-width: 768px) {
    padding-top: 0.5rem;
    padding-bottom: 120px;
  }
`;

const Group = styled.div`
  padding-bottom: 2rem;
`;

const Container = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(
    auto-fit,
    minmax(230px, ${(props) => (props.count === 2 ? '390px' : '1fr')})
  );

  @media only screen and (min-width: 1400px) {
    grid-gap: 3rem;
    grid-template-columns: repeat(
      auto-fit,
      minmax(280px, ${(props) => (props.count === 2 ? '390px' : '1fr')})
    );
  }

  @media only screen and (min-width: 1200px) {
    grid-gap: 2rem;
  }

  @media only screen and (max-width: 768px) {
    grid-template-columns: 50% 50%;
    grid-gap: 0;
    width: 100%;
    padding: 0 0.25rem;
  }
`;

const LoadingWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 200px;
  justify-content: center;
  align-items: center;
`;

export default Grid;
