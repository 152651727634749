import React, { memo } from 'react';
import styled from 'styled-components';

import star from '../../../assets/images/icon-star.svg';
import starFill from '../../../assets/images/icon-star-fill.svg';
import starEmpty from '../../../assets/images/icon-star-empty.svg';

const StarButton = ({ starred, size, onClick }) => (
  <Button title="Star Playlist" starred={starred} onClick={onClick}>
    <Image size={size} />
  </Button>
);

const Image = styled.div`
  width: ${(props) => (props.size === 'small' ? '22px' : '24px')};
  height: ${(props) => (props.size === 'small' ? '22px' : '24px')};
  background-color: transparent;

  background-repeat: no-repeat;
  background-position: 100%;
  background-size: auto ${(props) => (props.size === 'small' ? '16px' : '18px')};
`;

const Button = styled.div`
  cursor: pointer;

  ${Image} {
    background-image: url(${(props) => (props.starred ? star : starEmpty)});
  }

  ${({ starred }) =>
    !starred &&
    `
      &:hover {
        ${Image} {
          background-image: url(${starFill});
        }
      }
  `}
`;

export default memo(StarButton);
