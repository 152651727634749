// @flow
import React, { useMemo, useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import * as Realm from 'realm-web';
import { MdClose } from 'react-icons/md';
import { getDateCountdown } from '../../hooks/useTextHelper';
import { APP_ID, FREE_CLIP_COUNT } from '../../../constants';

const realm = Realm.App.getApp(APP_ID);

const CTA = {
  wasPro: {
    title: 'Subscription expired',
    body: '',
  },
  free: {
    title: 'Free Storage',
    body: 'Create and store up to 10 of your favorite clips. Upgrade to PRO to unlock unlimited storage.',
  },
};

const FreeAlert = ({ endDate, wasPro, count, limitExceeded, onDismiss }) => {
  const isInitialMount = useRef(true);

  const [showExpiry, setShowExpiry] = useState(false);
  const [loading, setLoading] = useState(true);

  const FILL_PCT = (count / FREE_CLIP_COUNT) * 100;
  const COPY = useMemo(() => CTA[showExpiry ? 'wasPro' : 'free'], [showExpiry]);
  const DAYS = useMemo(() => getDateCountdown(endDate) || null, [endDate]);

  const onDismissClick = () => {
    const clipCount = realm.currentUser?.customData?.clipCount || 0;
    const hideExpiry = wasPro && clipCount > FREE_CLIP_COUNT;

    if (hideExpiry) {
      // user is dismissing resubscribe message, save preference
      try {
        localStorage.setItem('hideExpiryNotice', true);
      } catch (err) {
        // catch error
      }
    }

    onDismiss();
  };

  useEffect(() => {
    const onInit = async () => {
      const _hideExpiry = localStorage.getItem('hideExpiryNotice');
      const expiry = _hideExpiry && wasPro && limitExceeded;
      setShowExpiry(expiry);
      setLoading(false);
    };

    if (isInitialMount.current) {
      isInitialMount.current = false;
      onInit();
    }
  }, [wasPro, limitExceeded]);

  return loading ? null : (
    <Wrapper>
      <Title expiry={showExpiry}>{COPY.title}</Title>
      <DismissBtn onClick={onDismissClick}>
        <MdClose />
      </DismissBtn>
      {showExpiry ? null : (
        <BarWrapper>
          <MeterBar fill={FILL_PCT}>
            <span />
          </MeterBar>
          <Count>{`${count} / ${FREE_CLIP_COUNT}`}</Count>
        </BarWrapper>
      )}

      {!showExpiry ? (
        <p>{COPY.body}</p>
      ) : DAYS ? (
        <p>
          In <b>{`${DAYS} days`}</b> your extended clip library will be
          permanently deleted. Your 10 most recent clips will always be saved.
          To keep your full library, reactivate your PRO subscription.
        </p>
      ) : (
        <p>
          Soon your extended clip library will be permanently deleted. Your 10
          most recent clips will always be saved. To keep your full library,
          reactivate your PRO subscription.
        </p>
      )}

      <Footer>
        <UpgradeBtn
          expiry={showExpiry}
          onClick={() => (window.location = '/upgrade')}>
          {showExpiry ? 'Reactivate' : 'Upgrade to PRO'}
        </UpgradeBtn>
      </Footer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.backgroundColors.secondary};
  border: 2px solid ${(props) => props.theme.colors.border};
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1rem;

  p {
    font-size: 13px;
    line-height: 1.4;
    color: ${(props) => props.theme.textColors.secondary};
    letter-spacing: 0.2px;
    margin-bottom: 15px;
    text-align: center;
  }
`;

const Title = styled.div`
  font-size: 17px;
  line-height: 1;
  font-weight: ${(props) => props.theme.fontWeights.medium};
  color: ${(props) => props.theme.textColors.primary};

  ${({ expiry }) =>
    expiry &&
    `
		text-align: center;
		margin-bottom: 12px;
	`}
`;

const DismissBtn = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  line-height: 1;

  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    font-size: 18px;
    color: ${(props) => props.theme.textColors.secondary};
    opacity: 0.6;
  }
`;

const BarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const MeterBar = styled.div`
  margin: 1rem 0;
  background-color: ${(props) => props.theme.colors.border};
  border-radius: 5px;
  height: 8px;
  flex: 1;
  overflow: hidden;

  span {
    display: block;
    border-radius: 10px;
    height: 100%;
    width: ${(props) => props.fill}%;
    background-color: ${(props) =>
      props.fill > 60
        ? props.theme.colors.secondary
        : props.fill > 30
        ? props.theme.colors.yellow
        : props.theme.colors.primary};
  }
`;

const Count = styled.div`
  font-size: 13px;
  color: ${(props) => props.theme.textColors.secondary};
  font-weight: ${(props) => props.theme.fontWeights.bold};
  padding-left: 12px;
  opacity: 0.6;
`;

const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const UpgradeBtn = styled.button`
  border-radius: 40px;
  height: 32px;
  color: white;
  font-size: 13px;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  background-color: ${(props) =>
    props.expiry ? props.theme.colors.secondary : props.theme.colors.primary};
  padding: 0 20px;
`;

export default FreeAlert;
