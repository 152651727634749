export const DOCUMENT_TITLE = 'Clipbox';
export const VERBOSE = false;
export const FREE_CLIP_COUNT = 10;

// ------ BROWSER INFO -----

const userAgent = navigator.userAgent;
export const IS_MOBILE = /android|iphone/i.test(userAgent);
export const IS_IOS = /iphone|ipad|ipod/.test(userAgent);
export const IS_SAFARI = /safari/.test(userAgent);
export const IS_STANDALONE = navigator.standalone;
export const IS_WEBVIEW = IS_IOS && !IS_SAFARI && !IS_STANDALONE;
export const ENV = location.hostname === 'localhost' ? 'dev' : 'prod';

// ------ KEYS -----

export const APP_ID = 'clipbox-nzfng';
export const GOOGLE_CLIENT_ID =
  '920198179689-m2qjiavug5tda5adof4el9tokcv0ou2h.apps.googleusercontent.com';
export const GOOGLE_ADSENSE_ID = 'ca-pub-3687846066773928';
export const AD_UNITS = {
  banner: '6906872207',
};

export const AMPLITUDE = {
  prod: 'dd5b9a8021ba6941719addc459b90dfa',
  dev: '7dd31391346f9b6b16c9df6179516a58',
};

// ----- CREATOR -----

export const CREATOR_URL = {
  dev: 'http://localhost:8400',
  // dev: 'https://creator.clipbox.app',
  beta: 'http://localhost:8400',
  prod: 'https://creator.clipbox.app',
};

// ------ CANCEL SUBSCRIPTION -----

export const CANCEL_URL = {
  ios: 'https://apps.apple.com/account/subscriptions',
  android:
    'https://play.google.com/store/account/subscriptions?package=Clipbox',
};
