// @flow
import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { AlertContext } from '../../contexts/AlertContext';
import Alert from './Alert';

const AlertCenter = () => {
  const {
    state: { alerts },
    dispatch: dispatchAlert,
  } = useContext(AlertContext);

  const [alert, setAlert] = useState(alerts[0]);

  const removeAlert = () => {
    dispatchAlert({ type: 'REMOVE_OLDEST_ALERT' });

    // clear the current alert so state change adds new alert
    setAlert(null);
  };

  useEffect(() => {
    setAlert(alerts[0]);
  }, [alerts]);

  return (
    <Container>
      {alert ? (
        <Alert
          id={alert.id}
          type={alert.type}
          icon={alert.icon}
          message={alert.message}
          showClose={alert.showClose}
          removeAlert={removeAlert}
        />
      ) : null}
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  pointer-events: none;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 768px) {
    justify-content: flex-end;
  }
`;

export default AlertCenter;
