import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';
import SidebarItem from './SidebarItem';
import EditModal from '../modals/EditModal';
import { ModalContext } from '../../contexts/ModalContext';
import { PlaylistsContext } from '../../contexts/PlaylistsContext';
import usePlaylistActions from '../../hooks/usePlaylistActions';

const Sidebar = ({ mode, playlistID, onPlaylistClick }) => {
  const { dispatch: dispatchModal } = useContext(ModalContext);
  const {
    state: { starred, playlists },
  } = useContext(PlaylistsContext);

  const { fetchPlaylists } = usePlaylistActions();

  const onPlaylistCreate = () => {
    dispatchModal({
      type: 'ADD_MODAL',
      data: {
        component: EditModal,
        props: {
          isNew: true,
          isPlaylist: true,
          maxWidth: '600px',
          borderRadius: '20px',
        },
      },
    });
  };

  useEffect(() => {
    fetchPlaylists();
  }, []);

  return (
    <Wrapper id="sidebar">
      <SidebarItem
        id="clips"
        title="My Clips"
        home
        selected={mode === 'home'}
        onClick={onPlaylistClick}
      />
      <Container>
        {starred.length > 0 ? (
          <Group>
            <GroupTitle>Starred</GroupTitle>
            <GroupContent>
              {starred.map((obj) => (
                <SidebarItem
                  key={obj._id.toString()}
                  id={obj._id}
                  title={obj.title}
                  selected={playlistID === obj._id.toString()}
                  starred
                  onClick={onPlaylistClick}
                />
              ))}
            </GroupContent>
          </Group>
        ) : null}

        {playlists.length > 0 ? (
          <Group>
            <GroupTitle>Playlists</GroupTitle>
            <GroupContent>
              {playlists.map((obj) => (
                <SidebarItem
                  key={obj._id.toString()}
                  id={obj._id}
                  title={obj.title}
                  selected={playlistID === obj._id.toString()}
                  onClick={onPlaylistClick}
                />
              ))}
            </GroupContent>
          </Group>
        ) : null}
      </Container>
      <Archived>
        <SidebarItem
          title="New Playlist"
          create
          onPlaylistCreate={onPlaylistCreate}
        />
      </Archived>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: fixed;
  display: none;
  top: 78px;
  left: 0;
  opacity: 1;
  border-right: 1px solid ${(props) => props.theme.colors.border};
  border-left: 1px solid ${(props) => props.theme.colors.border};
  min-width: 200px;
  height: calc(100% - 78px);
  padding: 1.5rem 0 0;
  background-color: transparent;

  @media only screen and (min-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

const Container = styled.div`
  overflow-y: auto;
  width: 100%;
  padding: 1rem 0;
`;

const Group = styled.div`
  padding: 0 0 1rem;

  &:last-child {
    padding: 0 !important;
  }
`;

const GroupTitle = styled.div`
  padding: 0.5rem 2rem;
  font-size: 13px;
  color: ${(props) => props.theme.textColors.secondary};
  text-transform: uppercase;
`;

const GroupContent = styled.div``;

const Archived = styled.div`
  margin-top: auto;
  width: 100%;
  border-top: 1px solid ${(props) => props.theme.colors.border};
`;

export default Sidebar;
