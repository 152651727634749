import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import * as Realm from 'realm-web';
import { darken } from 'polished';
import { MdCheckCircle, MdClose } from 'react-icons/md';
import ActivityIndicator from '../common/ActivityIndicator';
import { AlertContext } from '../../contexts/AlertContext';
import { APP_ID } from '../../../constants';
import PlaylistItem from '../common/PlaylistItem';

const realm = Realm.App.getApp(APP_ID);

const PlaylistModal = ({ clip, onDismiss }) => {
  const { dispatch: dispatchAlert } = useContext(AlertContext);

  const [data, setData] = useState();
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    const onInit = async () => {
      const results = await realm.currentUser.functions.getPlaylists();
      setData(results);
    };

    onInit();
  }, []);

  const onAddClick = async (playlistId) => {
    setSaving(true);

    console.log(playlistId.toString(), clip._id.toString());

    const result = await realm.currentUser.functions.addClipToPlaylist(
      playlistId.toString(),
      clip._id.toString(),
    );

    console.log(result);

    setSaving(false);

    if (result?.error) setError(result?.error);
    else {
      dispatchAlert({
        type: 'ADD_ALERT',
        data: {
          message: 'Clip Added to Playlist',
          type: 'toast',
          icon: MdCheckCircle,
        },
      });
      onDismiss();
    }
  };

  return (
    <Wrapper>
      <Header>
        <h2>Add clip to playlist</h2>
        <CloseBtn onClick={onDismiss}>
          <MdClose />
        </CloseBtn>
      </Header>
      <Content disabled={saving}>
        <LeftColumn>
          <Thumbnail src={clip?.preview} />
          <ClipTitle>{clip?.title}</ClipTitle>
        </LeftColumn>

        <RightColumn>
          <ListWrapper>
            {data ? (
              data.map((playlist, idx) => (
                <PlaylistItem
                  key={playlist._id}
                  config={playlist}
                  mode="add"
                  onClick={onAddClick}
                />
              ))
            ) : (
              <ActivityIndicator size={18} color="secondary" />
            )}
          </ListWrapper>
        </RightColumn>
      </Content>
      {error ? <ErrorMsg>{error}</ErrorMsg> : null}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  width: 100%;
  padding-bottom: 30px;
  background-color: ${(props) => props.theme.backgroundColors.lightAlt};

  @media only screen and (max-width: 768px) {
    height: 100%;
    padding-bottom: 0;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 25px 25px 25px 30px;

  h2 {
    font-size: 24px;
    color: ${(props) => props.theme.textColors.primary};
    flex: 1;
  }

  @media only screen and (max-width: 768px) {
    padding: 25px 20px 20px 25px;

    h2 {
      font-size: 22px;
    }
  }
`;

const CloseBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  cursor: pointer;

  width: 38px;
  height: 38px;
  border-radius: 50%;

  svg {
    font-size: 24px;
    color: ${(props) => props.theme.textColors.primary};
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.8);
  }

  @media only screen and (max-width: 768px) {
    background-color: rgba(255, 255, 255, 0.8);
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  padding: 0 30px;

  @media only screen and (max-width: 768px) {
    padding: 0;
    flex-direction: column;
    overflow-y: scroll;
  }
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.backgroundColors.lightAlt};
  justify-content: center;
  height: 100%;
  padding-right: 40px;

  @media only screen and (max-width: 768px) {
    padding: 15px 20px;
    flex-direction: row;
    height: auto;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    background-color: ${(props) =>
      darken(0.02, props.theme.backgroundColors.lightAlt)};
  }
`;

const RightColumn = styled.div`
  flex: 1;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid ${(props) => props.theme.colors.border};

  @media only screen and (max-width: 768px) {
    background-color: transparent;
    border: none;
    border-radius: 0;
    padding: 10px 0 10px 5px;
    overflow: initial;
  }
`;

const ListWrapper = styled.div`
  max-height: 240px;
  overflow-y: scroll;

  @media only screen and (max-width: 768px) {
    overflow-y: initial;
    max-height: initial;
  }
`;

const Thumbnail = styled.img`
  display: block;
  width: 140px;
  height: 105px;
  border-radius: 8px;
  overflow: hidden;
  object-fit: cover;

  @media only screen and (max-width: 768px) {
    width: 80px;
    height: 60px;
    margin-right: 20px;
  }
`;

const ClipTitle = styled.div`
  font-size: 14px;
  font-weight: ${(props) => props.theme.fontWeights.medium};
  color: ${(props) => props.theme.textColors.secondary};
  width: 140px;
  line-height: 1.4;
  margin-top: 10px;
  margin-bottom: 20px;

  @media only screen and (max-width: 768px) {
    width: auto;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 15px;
    color: ${(props) => props.theme.textColors.primary};
  }
`;

const ErrorMsg = styled.p`
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0.2px;
  color: ${(props) => props.theme.colors.warning};
  text-align: center;
  flex: 1;
`;

export default PlaylistModal;
