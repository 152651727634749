import React, { useEffect, useContext, useState } from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import { SystemContext } from './contexts/SystemContext';
import RootSwitch from './router/RootSwitch';
import SplashScreen from './components/common/SplashScreen';
import { AMPLITUDE, ENV } from '../constants';

const App = () => {
  const {
    state: { loadUI, realmUser },
  } = useContext(SystemContext);

  const [loading, setLoading] = useState(true);

  // detect if in webview (ios only right now)
  useEffect(() => {
    if (loadUI) {
      amplitude.init(AMPLITUDE[ENV]);
      setLoading(false);
    }
  }, [loadUI]);

  return loading ? (
    <SplashScreen />
  ) : (
    <RootSwitch isAuthenticated={realmUser} />
  );
};

export default App;
