// @flow
import React, { useEffect, useContext, useRef, useCallback } from 'react';
import styled from 'styled-components';
import * as Realm from 'realm-web';
import { MdOutlineLink } from 'react-icons/md';
import Button from '../common/Button';
import { APP_ID } from '../../../constants';

const realm = Realm.App.getApp(APP_ID);

const ShareLinkModal = ({ clip, onCopy, onDismiss }) => {
  const generateLink = async () => {
    // For some reason there's an issue passing an ObjectId into a function, so converting it to a string
    const shareId = await realm.currentUser.functions.generateShareId(
      clip._id.toString(),
    );
    onCopy(shareId);
    onDismiss();
  };

  return (
    <Wrapper>
      <Icon>
        <MdOutlineLink />
      </Icon>
      <Title>Generate Share Link</Title>
      <BodyTxt>
        Create and share a link to your clip hosted on clipbox.app.
      </BodyTxt>
      <Disclaimer>
        Anyone with the generated link can view your clip.
      </Disclaimer>
      <BtnWrapper>
        <Button onClick={onDismiss} faded>
          Cancel
        </Button>
        <Button onClick={generateLink} primary>
          Generate Share Link
        </Button>
      </BtnWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 2rem;

  @media only screen and (max-width: 768px) {
    margin: 20px;
    border-radius: 20px;
    padding-bottom: 1rem;
  }
`;

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  width: 80px;
  height: 80px;
  border-radius: 80px;
  background-color: rgba(${(props) => props.theme.colors.secondaryRGB}, 0.1);

  svg {
    color: ${(props) => props.theme.colors.secondary};
    font-size: 42px;
  }
`;

const Title = styled.div`
  font-size: 17px;
  font-weight: 700;
  color: ${(props) => props.theme.textColors.primary};
  letter-spacing: 0.2px;
  text-align: center;
  margin-bottom: 8px;
`;

const BodyTxt = styled.p`
  font-size: 14px;
  line-height: 1.4;
  font-weight: 400;
  color: ${(props) => props.theme.textColors.primary};
  text-align: center;
`;

const Disclaimer = styled.p`
  font-size: 13px;
  line-height: 1.4;
  color: ${(props) => props.theme.textColors.secondary};
  text-align: center;
  margin: 12px 0;
`;

const BtnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 20px;

  button {
    margin: 0 5px;
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column-reverse;
    width: 100%;

    button {
      margin: 5px;
      width: 100%;
      font-size: 15px;
      height: 44px;
      border-radius: 44px;

      &:first-child {
        background-color: transparent;
      }
    }
  }
`;

export default ShareLinkModal;
