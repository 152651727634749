// @flow
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { findObjectInArray } from '../../hooks/useTextHelper';

const PredictiveTagsRow = ({ tags, restricted, onTagClick }) => {
  const [list, setList] = useState(restricted ? [] : tags);

  useEffect(() => {
    if (tags && restricted) {
      let arr = [];
      tags.forEach((item) => {
        const match = findObjectInArray(restricted, 'tag', item.tag);
        if (!match) arr.push(item);
      });
      setList(arr);
    }
    if (!tags) setList([]);
  }, [tags, restricted]);

  return list.length > 0 ? (
    <TagsRow>
      <FlatList>
        {list.map((tag) => (
          <TagBtn
            key={tag}
            onClick={() =>
              onTagClick(tag)
            }>{`# ${tag.tagLabel.toLowerCase()}`}</TagBtn>
        ))}
      </FlatList>
    </TagsRow>
  ) : null;
};

const TagsRow = styled.div`
  background-color: white;
  border-bottom: 1px solid ${(props) => props.theme.colors.border};
  width: 100%;
`;

const FlatList = styled.div`
  padding: 10px;
  overflow-x: scroll;
`;

const TagBtn = styled.button`
  background-color: ${(props) => props.theme.backgroundColor.lightAlt};
  border: 1px solid rgba(${(props) => props.theme.textColors.primaryRGB}, 0.1);
  border-radius: 25px;
  padding: 8px 12px;
  margin: 5px;
  color: ${(props) => props.theme.textColors.primary};
  font-size: 13px;
  font-weight: bold;
`;

export default PredictiveTagsRow;
