import React, { useState } from 'react';
import styled from 'styled-components';
import { MdAccountCircle } from 'react-icons/md';

const UserIcon = ({ user, size = '50px' }) => {
  const [error, setError] = useState(false);

  const onImageError = () => setError(true);

  return (
    <Container size={size} hasPhoto={!error && user?.customData?.photo}>
      {!error && user?.customData?.photo ? (
        <Image src={user.customData.photo} onError={onImageError} />
      ) : (
        <MdAccountCircle size={size} />
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  border-radius: ${(props) => props.size};
  overflow: hidden;
  background-color: ${(props) =>
    props.hasPhoto ? props.theme.backgroundColors.lightAlt : 'transparent'};

  svg {
    color: ${(props) => props.theme.textColors.secondary};
  }
`;

const Image = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export default UserIcon;
