import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { RiVideoAddFill } from 'react-icons/ri';
import Button from './Button';
import SearchBar from './SearchBar';
import UserIcon from './UserIcon';
import WithTooltip from './WithTooltip';
import AccountModal from '../modals/AccountModal';
import { ModalContext } from '../../contexts/ModalContext';
import { SystemContext } from '../../contexts/SystemContext';
import { openCreator } from '../../api/creator';

import logo from '../../../assets/images/logo.svg';
import mobileMenuIcon from '../../../assets/images/icon-mobile-menu.svg';

const DashHeader = ({ isPro, onMobileMenu }) => {
  const { dispatch: dispatchModal } = useContext(ModalContext);
  const {
    state: { realmUser },
  } = useContext(SystemContext);

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const onAccountClick = () => {
    dispatchModal({
      type: 'ADD_MODAL',
      data: {
        component: AccountModal,
        props: {
          maxWidth: '600px',
          borderRadius: '12px',
        },
      },
    });
  };

  const onMobileMenuClick = () => {
    setMobileMenuOpen(!mobileMenuOpen);
    onMobileMenu();
  };

  return (
    <header>
      <Wrapper>
        <LogoWrapper>
          <Link to="/">
            <Logo>Clipbox</Logo>
          </Link>
        </LogoWrapper>

        {isPro ? <SearchBar /> : null}

        <NavBar>
          <NavItem create mobile>
            <WithTooltip title="Create Clip" bottom>
              <a onClick={openCreator}>
                <RiVideoAddFill />
              </a>
            </WithTooltip>
          </NavItem>

          {/* <NavItem studio active={location.pathname === '/studio'}>
            <Link to="/studio">
              Studio<ProBadge>PRO</ProBadge>
            </Link>
          </NavItem> */}
          {isPro || location.pathname === '/upgrade' ? null : (
            <NavItem narrow>
              <Link to="/upgrade">
                <Button primary>Upgrade to Pro</Button>
              </Link>
            </NavItem>
          )}
          <NavItem avatar>
            <a onClick={onAccountClick}>
              <UserIcon user={realmUser} size="38px" />
            </a>
          </NavItem>
          <MobileMenuBtn mobile onClick={onMobileMenuClick} />
        </NavBar>
      </Wrapper>
      <Sidebar open={mobileMenuOpen}>
        {mobileMenuOpen ? (
          <NavBar>
            {/* <MobileNavItem>
              <Link to="/studio">
                <Row>
                  Studio<ProBadge>PRO</ProBadge>
                </Row>
              </Link>
            </MobileNavItem> */}
            <MobileNavItem>
              <Link to="/account">Account Settings</Link>
            </MobileNavItem>
            <MobileNavItem signout>
              <Link to="/logout">Sign out</Link>
            </MobileNavItem>
          </NavBar>
        ) : null}
      </Sidebar>
    </header>
  );
};

const Wrapper = styled.div`
  position: fixed;
  z-index: 100;
  top: 0;
  width: 100%;
  display: flex;
  background-color: white;
  box-shadow: 0 2px 4px rgba(85, 116, 162, 0.2);
  padding: 20px 40px 20px 0;
  line-height: 2rem;
  font-size: 0.875rem;
`;

const LogoWrapper = styled.div`
  display: flex;
  min-width: 200px;
  padding-left: 2rem;
  margin-right: 2rem;

  a {
    display: flex;
    justify-content: center;
  }

  @media only screen and (max-width: 922px) {
    min-width: auto;
  }

  @media only screen and (max-width: 768px) {
    padding-left: 1rem;
    margin-right: 1rem;
  }
`;

const Logo = styled.div`
  min-width: 120px;
  background: url(${logo}) no-repeat 50%;
  background-size: contain;
  font-size: 0;

  @media only screen and (max-width: 922px) {
    min-width: 40px;
    background-size: cover;
    background-position: 0 center;
  }
`;

const NavBar = styled.ul`
  display: flex;
  align-items: center;
  margin: 0;
  margin-left: auto;
  padding: 0;
  list-style: none;
`;

const NavItem = styled.li`
  position: relative;
  margin-right: 3rem;

  a {
    display: flex;
    justify-content: center;
    font-size: 16px;
    color: ${(props) =>
      props.signout
        ? props.theme.colors.warning
        : props.theme.textColors.primary};
    font-weight: ${(props) =>
      props.active
        ? props.theme.fontWeights.bold
        : props.theme.fontWeights.regular};

    &:hover {
      opacity: 0.8;
    }
  }

  ${({ create, theme }) =>
    create &&
    `
		margin-right: 2.5rem !important;

		svg {
			font-size: 28px;
			color: ${theme.textColors.primary};
			transform: scaleX(1.1);
			
		}
		a {
			opacity: 0.8;

			&:hover {
				opacity: 1 !important;
			}
		}
	`}

  ${({ studio }) =>
    studio &&
    `
		margin-right: 4rem !important;
	`}

  ${({ narrow }) =>
    narrow &&
    `
    margin-right: 2rem;
  `}

  ${({ avatar }) =>
    avatar &&
    `
		margin-right: 0 !important;
		min-width: 2.5rem;

		a {
			padding: 0;
		}
	`}

	@media only screen and (max-width: 992px) {
    margin-right: 2rem;
  }
  @media only screen and (max-width: 922px) {
    display: none;

    ${({ mobile }) =>
      mobile &&
      `
			display: block !important;
			margin-right: 0 !important;
			margin-left: 1.5rem;
		`}
  }
`;

const ProBadge = styled.span`
  position: absolute;
  top: 0;
  right: -28px;
  background: linear-gradient(
    to right top,
    ${(props) => props.theme.colors.secondaryLight},
    ${(props) => props.theme.colors.secondary}
  );
  color: white;
  text-transform: uppercase;
  border-radius: 15px;
  padding: 4px 5px 3px;
  line-height: 1;
  font-size: 0.5rem;
  font-weight: 700;
  user-select: none;
`;

const MobileMenuBtn = styled(NavItem)`
  display: none;
  width: 1.5rem;
  height: 1.5rem;
  background: url(${mobileMenuIcon}) no-repeat;
  background-position: 50%;
  background-size: 1.2rem;
  cursor: pointer;
`;

const Sidebar = styled.div`
  position: fixed;
  top: 0;
  left: ${(props) => (props.open ? 'auto' : '-200px')};
  right: ${(props) => (props.open ? '-200px' : 'auto')};
  transition: background-color 0.5s linear, border-color 0.5s linear;
  border-left: 1px solid ${(props) => props.theme.colors.border};
  box-shadow: inset 4px 0 8px rgba(85, 116, 162, 0.06);
  padding-top: 0.5rem;
  width: 200px;
  height: 100%;
  background-color: white;

  ${NavBar} {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
`;

const MobileNavItem = styled(NavItem)`
  a {
    position: relative;
    font-size: 16px;
    line-height: 64px;
    padding-left: 32px;
  }

  ${ProBadge} {
    position: relative;
    top: initial;
    right: initial;
    display: inline;
    margin-left: 4px;
  }

  @media only screen and (max-width: 992px) {
    display: block;
  }
`;

export default DashHeader;
