import React, { useState, useEffect, useRef, useContext } from 'react';
import styled from 'styled-components';
import * as Realm from 'realm-web';
import { useLocation } from 'react-router-dom';
import { MdChevronRight } from 'react-icons/md';
import DashLayout from '../layouts/DashLayout';
import Grid from '../components/home/Grid';
import Sidebar from '../components/home/Sidebar';
import Button from '../components/common/Button';
import EmptyState from '../components/common/EmptyState';
import StarButton from '../components/common/StarButton';
import FreeList from '../components/home/FreeList';
import ActionSheetModal from '../components/modals/ActionSheetModal';
import Upgrade from '../components/upgrade/Upgrade';
import { ClipsContext } from '../contexts/ClipsContext';
import { ModalContext } from '../contexts/ModalContext';
import { SystemContext } from '../contexts/SystemContext';
import usePlaylistActions from '../hooks/usePlaylistActions';
import { APP_ID, FREE_CLIP_COUNT, IS_MOBILE } from '../../constants';

const realm = Realm.App.getApp(APP_ID);

const HomePage = () => {
  const isInitialMount = useRef(true);

  const { state: clips, dispatch: dispatchClips } = useContext(ClipsContext);
  const { dispatch: dispatchModal } = useContext(ModalContext);
  const {
    state: { isPro },
  } = useContext(SystemContext);

  const location = useLocation();
  const { loadPlaylist, editPlaylist, deletePlaylist, starPlaylist } =
    usePlaylistActions();

  const [loading, setLoading] = useState(true);

  const onStar = (e) => {
    e.stopPropagation();
    if (clips?.playlist?._id) {
      const starred = clips.playlist.starred;
      starPlaylist(clips.playlist._id.toString(), !starred);
    }
  };

  const onPreviewClip = (clip) => {
    dispatchClips({
      type: 'SET_PREVIEW',
      data: clip,
    });
  };

  const onPlaylistClick = async (id, isHome) => {
    if (isHome) {
      onResetToMyClips();
      return;
    }

    loadPlaylist(id);
  };

  const onResetToMyClips = () => {
    window.history.replaceState({}, document.title, '/');
    setLoading(true);
    dispatchClips({
      type: 'CLEAR',
    });
  };

  const onCreate = () => {
    window.location = '/creator';
  };

  const onMobileLongPress = (e, config) => {
    e.stopPropagation();

    dispatchModal({
      type: 'ADD_MODAL',
      data: {
        component: ActionSheetModal,
        props: {
          config,
        },
      },
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const results = await realm.currentUser.functions.getClips();
      setLoading(false);

      dispatchClips({
        type: 'SET_LIST',
        data: {
          title: 'My Clips',
          count: results.length,
          list: results,
          type: 'home',
        },
      });
    };

    // if search param exists, searchBar handles initial clip load
    const urlParams = new URLSearchParams(window.location.search);
    const searchParam = urlParams.get('q');
    if (isInitialMount.current && searchParam) {
      isInitialMount.current = false;
      return;
    }

    // after search/playlist cleared but page not refreshed, load all clips
    if (isInitialMount.current || !clips.list) {
      isInitialMount.current = false;
      fetchData();
    }
  }, [clips.list]);

  return (
    <DashLayout isPro={isPro}>
      <Content isPro={isPro}>
        {isPro && !IS_MOBILE ? (
          <Sidebar
            mode={clips?.type}
            playlistID={clips?.playlist?._id.toString()}
            onPlaylistClick={onPlaylistClick}
          />
        ) : null}

        {IS_MOBILE ? null : (
          <HeaderWrapper>
            {clips?.list?.length > 0 || clips?.type !== 'home' ? (
              <Header>
                <TitleWrapper>
                  <Title
                    disabled={clips?.type === 'home'}
                    onClick={onResetToMyClips}>
                    My Clips
                  </Title>
                  {clips?.type !== 'home' ? (
                    <>
                      <Caret>
                        <MdChevronRight />
                      </Caret>
                      <Title disabled>
                        {clips?.title ? (
                          clips?.type === 'search' ? (
                            <span>
                              Showing results for "<b>{clips.title}</b>"
                            </span>
                          ) : clips?.playlist ? (
                            clips.playlist.title
                          ) : (
                            clips.title
                          )
                        ) : (
                          ''
                        )}
                      </Title>
                    </>
                  ) : null}

                  <Count>{`(${clips?.count || 0})`}</Count>
                  {clips?.type === 'playlist' ? (
                    <StarButton
                      starred={clips?.playlist?.starred}
                      onClick={onStar}
                    />
                  ) : null}
                </TitleWrapper>
                {clips?.type === 'search' ? (
                  <Button outlined onClick={onResetToMyClips}>
                    Clear Search
                  </Button>
                ) : clips?.type === 'playlist' ? (
                  <ButtonsWrapper>
                    <Button outlined onClick={editPlaylist}>
                      Edit Playlist
                    </Button>
                    <Button outlined onClick={deletePlaylist}>
                      Delete
                    </Button>
                  </ButtonsWrapper>
                ) : !isPro ? (
                  <FreeCounter>{`${Math.min(
                    FREE_CLIP_COUNT,
                    clips?.count,
                  )}/${FREE_CLIP_COUNT} clips created. Upgrade for unlimited.`}</FreeCounter>
                ) : null}
              </Header>
            ) : null}
          </HeaderWrapper>
        )}

        {clips?.list?.length > 0 ? (
          IS_MOBILE && !isPro ? (
            <FreeList data={clips?.list} onClipClick={onPreviewClip} />
          ) : (
            <Explorer>
              <Grid
                data={
                  isPro ? clips?.list : clips?.list?.slice(0, FREE_CLIP_COUNT)
                }
                onClipClick={onPreviewClip}
                onMenuClick={onMobileLongPress}
              />
            </Explorer>
          )
        ) : loading ? null : (
          <EmptyWrapper>
            <EmptyState
              title={`No clips ${clips?.playlist ? 'added' : 'created'}`}
              subtitle={`You haven't  ${
                clips?.playlist ? 'added' : 'saved'
              } any clips yet.<br />When you do, they'll show up here.`}
              btnLabel={` ${clips?.playlist ? 'Add' : 'Create'} my first clip`}
              onClick={clips?.playlist ? null : onCreate}
            />
          </EmptyWrapper>
        )}

        {clips?.list?.length > 0 ? (
          isPro || IS_MOBILE ? null : (
            <div>
              <UpgradeGradient />
              <Upgrade />
            </div>
          )
        ) : null}
      </Content>
    </DashLayout>
  );
};

const Caret = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 30px;

  svg {
    font-size: 26px;
    color: ${(props) => props.theme.textColors.primary};
  }
`;

const FreeCounter = styled.div`
  font-size: 14px;
  color: ${(props) => props.theme.textColors.secondary};
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;

  button {
    margin-left: 1rem;
  }
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;

  display: flex;
  flex-direction: column;
  flex: 1;

  padding-left: ${(props) => (props.isPro ? '200px' : '0')};

  @media only screen and (max-width: 768px) {
    padding-left: 0;
  }
`;

const Explorer = styled.div`
  padding: 0 2rem;

  @media only screen and (max-width: 768px) {
    padding: 0;
  }
`;

const HeaderWrapper = styled.div`
  position: sticky;
  z-index: 2;
  top: 0;
  margin: 0 2px 0;
  padding: 1rem 2rem 0;
  background-color: ${(props) => props.theme.backgroundColors.primary};

  @media only screen and (max-width: 768px) {
    padding: 0 1rem;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.colors.border};
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  padding: 0.5rem 0 0.5rem 7px;
`;

const Title = styled.div`
  padding: 3px 0;
  font-size: 20px;
  font-weight: bold;
  line-height: 32px;
  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    `
    cursor: default;
    pointer-events: none;
    font-weight: 500;
  `}

  &:hover {
    color: ${(props) => props.theme.colors.secondary};
  }
`;

const Count = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: ${(props) => props.theme.textColors.secondary};
  padding: 0 7px;
`;

const UpgradeGradient = styled.div`
  width: 100%;
  height: 200px;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  margin-top: -180px;
`;

const EmptyWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export default HomePage;
