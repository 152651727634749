import React from 'react';
import styled from 'styled-components';
import logo from '../../../assets/images/appicon.png';

const SplashScreen = () => (
  <Wrapper>
    <Logo src={logo} />
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
  height: 100%;
  background-color: white;
`;

const Logo = styled.img`
  width: 160px;
`;

export default SplashScreen;
