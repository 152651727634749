import React from 'react';
import { MdClose } from 'react-icons/md';
import styled from 'styled-components';
import appIcon from '../../../assets/images/appicon.png';

const InstallPrompt = ({ isPro, onInstall, onDismiss }) => (
  <Wrapper isPro={isPro}>
    <Row>
      <CloseBtn onClick={onDismiss}>
        <MdClose />
      </CloseBtn>
      <TextWrapper>
        <Title>Add to Home Screen</Title>
        <p>Get back here faster by launching directly from your phone.</p>
      </TextWrapper>
      <InstallBtn onClick={onInstall}>Install</InstallBtn>
    </Row>
  </Wrapper>
);

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.colors.primary};
  padding: 16px 10px;
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CloseBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 5px;

  svg {
    color: white;
    font-size: 24px;
  }
`;

const InstallBtn = styled.button`
  color: ${(props) => props.theme.colors.primary};
  font-weight: bold;
  font-size: 15px;
  background-color: white;
  height: 36px;
  padding: 0 16px;
  border-radius: 20px;
  letter-spacing: 0.2px;
`;

const AppIcon = styled.div`
  width: 64px;
  height: 64px;
  background: url(${appIcon}) no-repeat 50%;
  background-size: cover;
  border-radius: 12px;
  border: 2px solid ${(props) => props.theme.colors.border};
`;

const TextWrapper = styled.div`
  flex: 1;
  padding: 0 20px;

  p {
    color: white;
    font-size: 14px;
    font-weight: ${(props) => props.theme.fontWeights.medium};
    line-height: 1.2;
  }
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 15px;
  line-height: 1;
  color: white;
  margin-bottom: 4px;
`;

export default InstallPrompt;
