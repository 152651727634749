// @flow
import React, { createContext, useReducer } from 'react';
import { findObjectIndexInArray } from '../hooks/useTextHelper';

const INITIAL_STATE = {
  title: 'My Clips',
  count: 0,
  list: null,
  type: 'home', // tells HomePage Header what info to display above Grid
  playlist: null,
  preview: null,
};

export const ClipsContext = createContext(INITIAL_STATE);

const Context = ({ children }) => {
  const reducer = (state, { type, data }) => {
    switch (type) {
      case 'SET_PREVIEW': {
        // if updating preview item after saving an edit, update item in list
        let copyOfList = [...state.list];
        if (data && state.preview) {
          const idx = findObjectIndexInArray(state.list, '_id', data._id);
          copyOfList[idx] = data;
        }

        return {
          ...state,
          list: copyOfList,
          preview: data,
        };
      }
      case 'SET_LIST': {
        return {
          ...state,
          playlist: null,
          ...data,
        };
      }
      case 'UPDATE_PLAYLIST': {
        return {
          ...state,
          playlist: {
            ...state.playlist,
            ...data,
          },
        };
      }
      case 'CLEAR': {
        return INITIAL_STATE;
      }
      default: {
        return state;
      }
    }
  };

  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <ClipsContext.Provider value={{ state, dispatch }}>
      {children}
    </ClipsContext.Provider>
  );
};

export default Context;
