import React, { useState } from 'react';
import styled from 'styled-components';
import { FaRegTrashAlt } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';

import ActivityIndicator from '../common/ActivityIndicator';
import Button from '../common/Button';
import TagsInput from '../common/TagsInput';
import PlaylistThumbnail from '../common/PlaylistThumbnail';

import usePlaylistActions from '../../hooks/usePlaylistActions';
import useClipActions from '../../hooks/useClipActions';

import { IS_MOBILE } from '../../../constants';

const MAX_TITLE = 100;

const EditModal = ({ config, isNew, isPlaylist, onDismiss }) => {
  const DEFAULT_TITLE = isPlaylist ? 'Untitled Playlist' : '';

  const { updateClip, deleteClip } = useClipActions(config);
  const { createPlaylist, deletePlaylist, updatePlaylist } =
    usePlaylistActions();

  const [title, setTitle] = useState(config?.title || DEFAULT_TITLE);
  const [description, setDescription] = useState(config?.description);
  const [tags, setTags] = useState(config?.tags || []);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState();

  const onTagsUpdate = (arr) => {
    setTags(arr);
  };

  const savePlaylist = async (e) => {
    e.stopPropagation();

    setSaving(true);

    try {
      const formData = { title, description };

      if (isNew) await createPlaylist(formData, onDismiss);
      else {
        const changeLog = {
          title: title !== config.title,
          description: description !== config.description,
        };
        await updatePlaylist(
          config._id.toString(),
          formData,
          changeLog,
          onDismiss,
        );
      }
    } catch (err) {
      console.log('Error saving changes to playlist: ', err);
      setSaving(false);
      setError('There was an error saving your changes. Please try again.');
    }
  };

  const saveClip = async (e) => {
    e.stopPropagation();
    setSaving(true);

    try {
      const formData = { title, tags };
      const changeLog = {
        title: title !== config.title,
        tags: (config.tags?.length || 0) !== tags.length,
      };

      await updateClip(config._id.toString(), formData, changeLog, onDismiss);
    } catch (err) {
      setSaving(false);
      setError('There was an error saving your changes. Please try again.');
    }
  };

  const TITLE_COUNT = MAX_TITLE - title.length;

  return (
    <Wrapper>
      <Header>
        <h2>{`${isNew ? 'Create' : 'Edit'} ${
          isPlaylist ? 'playlist' : 'clip'
        } ${isNew ? '' : 'details'}`}</h2>
        <CloseBtn onClick={onDismiss}>
          <MdClose />
        </CloseBtn>
        <Button onClick={isPlaylist ? savePlaylist : saveClip} primary>
          {saving ? <ActivityIndicator size={18} color="white" /> : 'Save'}
        </Button>
      </Header>
      <Content disabled={saving}>
        <LeftColumn>
          {isPlaylist ? (
            <PlaylistThumbnail images={config?.image} size="large" />
          ) : (
            <Thumbnail src={config?.preview} />
          )}
        </LeftColumn>
        <RightColumn>
          <TitleWrapper>
            <TitleInput
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder={`Enter a title for your ${
                isPlaylist ? 'playlist' : 'clip'
              } ...`}
              maxLength={MAX_TITLE}
              disabled={saving}
            />
            <TitleCount warn={TITLE_COUNT < 10} isVisible={TITLE_COUNT < 21}>
              {TITLE_COUNT}
            </TitleCount>
          </TitleWrapper>
          {isPlaylist ? (
            <Description
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Optional: Add a description for your playlist"
              maxLength={255}
              disabled={saving}
            />
          ) : (
            <TagsWrapper>
              <TagsInput tags={tags} onTagsUpdate={onTagsUpdate} />
            </TagsWrapper>
          )}
          {IS_MOBILE ? (
            <DeleteWrapper>
              {error ? <ErrorMsg>{error}</ErrorMsg> : null}
              <DeleteBtn
                onClick={() => (isPlaylist ? deletePlaylist() : deleteClip())}>
                <FaRegTrashAlt />
              </DeleteBtn>
            </DeleteWrapper>
          ) : null}
        </RightColumn>
      </Content>

      {IS_MOBILE ? null : (
        <Footer>
          {error ? (
            <ErrorMsg style={{ textAlign: 'left' }}>{error}</ErrorMsg>
          ) : null}

          <Button onClick={isPlaylist ? savePlaylist : saveClip} primary>
            {saving ? (
              <ActivityIndicator size={18} color="white" />
            ) : (
              `Save ${isPlaylist ? 'Playlist' : 'Clip'}`
            )}
          </Button>
        </Footer>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  width: 100%;
  background-color: ${(props) => props.theme.backgroundColors.lightAlt};

  @media only screen and (max-width: 768px) {
    height: 100%;
    background-color: white;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 25px 25px 25px 30px;

  h2 {
    font-size: 24px;
    color: ${(props) => props.theme.textColors.primary};
    flex: 1;
  }

  button {
    display: none;
  }

  @media only screen and (max-width: 768px) {
    padding: 10px;
    border-bottom: 1px solid ${(props) => props.theme.colors.border};
    align-items: center;

    h2 {
      text-align: center;
      font-size: 16px;
      font-family: ${(props) => props.theme.fonts.secondary};
      order: 2;
      padding-left: 22px;
    }

    button {
      display: block;
      order: 3;
      border-radius: 6px;
      height: 36px;
      min-width: 56px;
      padding: 0;
    }
  }
`;

const CloseBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  cursor: pointer;

  width: 38px;
  height: 38px;
  border-radius: 50%;

  svg {
    font-size: 24px;
    color: ${(props) => props.theme.textColors.primary};
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.8);
  }

  @media only screen and (max-width: 768px) {
    order: 1;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  padding: 0 30px;

  @media only screen and (max-width: 768px) {
    padding: 0;
    flex-direction: column;
    overflow-y: scroll;
  }
`;

const LeftColumn = styled.div`
  background-color: ${(props) => props.theme.backgroundColors.lightAlt};

  @media only screen and (max-width: 768px) {
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    margin-bottom: 20px;
  }
`;

const RightColumn = styled.div`
  flex: 1;
  padding-left: 20px;

  @media only screen and (max-width: 768px) {
    padding: 0 20px;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  background-color: white;
  border: 1px solid ${(props) => props.theme.colors.border};
  border-radius: 7px;
  padding: 8px 12px;
  margin-bottom: 16px;
`;

const TitleInput = styled.input`
  background-color: transparent;
  border: none;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  flex: 1;
  resize: none;
  padding: 0;
  border-radius: 0;
  width: 100%;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
`;

const TitleCount = styled.span`
  color: ${(props) => props.theme.textColors.secondary};
  font-weight: 500;
  font-size: 15px;
  margin-left: 8px;
  opacity: ${(props) => (props.isVisible ? 1 : 0)};

  ${({ warn, theme }) =>
    warn &&
    `
		color: ${theme.colors.secondary};
		font-weight: bold;
		opacity: 1;
	`}
`;

const TagsWrapper = styled.div`
  margin-top: 4px;
`;

const Thumbnail = styled.img`
  display: block;
  width: 160px;
  height: 120px;
  border-radius: 8px;
  overflow: hidden;
  object-fit: cover;

  @media only screen and (max-width: 768px) {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }
`;

const Description = styled.textarea`
  background-color: white;
  border: 1px solid ${(props) => props.theme.colors.border};
  border-radius: 7px;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  flex: 1;
  resize: none;
  padding: 10px 12px;
  width: 100%;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  margin-bottom: 16px;

  &:focus {
    border-color: ${(props) => props.theme.colors.border};
  }
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: auto;
  padding: 20px 30px;
  width: 100%;
  border-top: 1px solid ${(props) => props.theme.colors.border};

  button {
    margin-left: 10px;
    min-width: 100px;
  }

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const ErrorMsg = styled.p`
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0.2px;
  color: ${(props) => props.theme.colors.warning};
  text-align: center;
  flex: 1;

  @media only screen and (max-width: 768px) {
    padding: 0 30px;
    font-size: 14px;
    margin-bottom: 20px;
  }
`;

const DeleteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 40px;
`;

const DeleteBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.warning};

  svg {
    font-size: 24px;
    color: white;
  }
`;

export default EditModal;
