// @flow
import React, { useEffect, useState, useMemo, useRef, useContext } from 'react';
import styled from 'styled-components';
import { formatDistanceStrict } from 'date-fns';
import { FaPaperPlane } from 'react-icons/fa';
import { MdClose, MdPlaylistAdd, MdOutlineLink } from 'react-icons/md';
import Video from '../common/Video';
import { ClipsContext } from '../../contexts/ClipsContext';
import { SystemContext } from '../../contexts/SystemContext';
import useClipActions from '../../hooks/useClipActions';

const ClipPreview = ({ config }) => {
  const isInitialMount = useRef(false);
  const vidRef = useRef(null);

  const { dispatch: dispatchClips } = useContext(ClipsContext);
  const {
    state: { isPro },
  } = useContext(SystemContext);

  const {
    addToPlaylist,
    downloadClip,
    editClip,
    shareClip,
    copyLink,
    requiresPro,
  } = useClipActions(config);

  const [showBtnLabels, setShowBtnLabels] = useState(true);

  const creationDate = useMemo(() => {
    return formatDistanceStrict(config?.created, new Date(), {
      addSuffix: true,
    });
  }, [config?.created]);

  const onClose = () => {
    dispatchClips({
      type: 'SET_PREVIEW',
      data: null,
    });
  };

  const onVideoLoad = () => {
    // autoplay video
    vidRef.current.play();
  };

  const onAction = (action) => {
    const onFinish = () => {
      vidRef.current.currentTime = 0;
      vidRef.current.play();
    };

    if (action === 'download' && isPro) {
      downloadClip();
      return;
    }

    if (action === 'addToPlaylist' && isPro) {
      addToPlaylist();
      return;
    }

    // quiet video in background
    vidRef.current.pause();

    if (action === 'share') {
      shareClip(onFinish);
    } else if (action === 'edit') {
      editClip(onFinish);
    } else if (action === 'copy') {
      copyLink(onFinish);
    } else if (action === 'download') {
      requiresPro('download', onFinish);
    } else if (action === 'addToPlaylist') {
      requiresPro('playlist', onFinish);
    }
  };

  useEffect(() => {
    const checkBtnLabels = () => {
      // once user has previewed 3 clips, remove btn labels and switch to icons only
      let viewCount = localStorage.getItem('previewBtnCounter');
      if (viewCount) viewCount = parseInt(viewCount, 10);
      if (!viewCount || viewCount < 3) setShowBtnLabels(true);
      localStorage.setItem(
        'previewBtnCounter',
        viewCount ? (viewCount + 1).toString() : '1',
      );
    };

    if (isInitialMount.current) {
      isInitialMount.current = false;
      checkBtnLabels();
    }
  }, []);

  return (
    <Wrapper>
      <VideoWrapper>
        <Video ref={vidRef} source={config?.clip} onLoad={onVideoLoad} />
      </VideoWrapper>
      <Overlay>
        <Gradient top />
        <Gradient bottom />
      </Overlay>
      <Content>
        <Header>
          <MetaBtn onClick={() => onAction('edit')}>
            <Title>{config?.title || 'Untitled Clip'}</Title>
            <CreationDate>{creationDate}</CreationDate>
          </MetaBtn>
          <CloseBtn onClick={onClose}>
            <MdClose />
          </CloseBtn>
        </Header>
        <Footer hasLabels={showBtnLabels}>
          <ActionBtn>
            <ActionOrb
              proRing={!isPro}
              onClick={() => onAction('addToPlaylist')}>
              <MdPlaylistAdd />
              {isPro ? null : <ProDot />}
            </ActionOrb>
            {showBtnLabels ? (
              <ActionBtnLabel>Add to Playlist</ActionBtnLabel>
            ) : null}
          </ActionBtn>

          <ActionBtn>
            <ActionOrb large onClick={() => onAction('share')}>
              <FaPaperPlane />
            </ActionOrb>
            {showBtnLabels ? <ActionBtnLabel>Share</ActionBtnLabel> : null}
          </ActionBtn>

          <ActionBtn>
            <ActionOrb onClick={() => onAction('copy')}>
              <MdOutlineLink />
            </ActionOrb>
            {showBtnLabels ? <ActionBtnLabel>Copy Link</ActionBtnLabel> : null}
          </ActionBtn>
        </Footer>
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.textColors.primary};
  flex: 1;
  height: 100%;
`;

const VideoWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
`;

const AbsoluteFill = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  pointer-events: none;
`;

const Content = styled(AbsoluteFill)``;

const Overlay = styled(AbsoluteFill)``;

const Gradient = styled.div`
  width: 100%;
  height: 180px;

  ${({ top, theme }) =>
    top &&
    `
		background: linear-gradient(180deg, rgba(${theme.textColors.primaryRGB}, 0.5), rgba(${theme.textColors.primaryRGB}, 0));
	`}

  ${({ bottom, theme }) =>
    bottom &&
    `
		background: linear-gradient(180deg, rgba(${theme.textColors.primaryRGB}, 0), rgba(${theme.textColors.primaryRGB}, 0.5));
		margin-top: auto;
	`}
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 8px;
`;

const MetaBtn = styled.button`
  padding: 10px;
  margin-right: auto;
  background: transparent;
  text-align: left;
  pointer-events: auto;
`;

const Title = styled.div`
  color: white;
  font-size: 14px;
  font-weight: ${(props) => props.theme.fontWeights.medium};
  margin-bottom: 3px;
`;

const CreationDate = styled.div`
  color: white;
  font-size: 13px;
  font-weight: ${(props) => props.theme.fontWeights.regular};
  opacity: 0.8;
`;

const CloseBtn = styled.button`
  background: transparent;
  margin-left: 10px;
  margin-right: 5px;
  pointer-events: auto;

  svg {
    color: white;
    font-size: 32px;
  }
`;

const ActionBtn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  pointer-events: auto;
  margin: 0 2px;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  padding: 10px 10px 30px;

  ${ActionBtn} {
    min-width: ${(props) => (props.hasLabels ? '100px' : '90px')};
  }
`;

const ActionOrb = styled.div`
  position: relative;
  background-color: rgba(255, 255, 255, 0.95);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    font-size: 30px;
    color: ${(props) => props.theme.colors.primaryDark};
  }

  ${({ proRing, theme }) =>
    proRing &&
    `
		width: 56px;
		height: 56px;
		border: 3px solid ${theme.colors.secondary};

		&::after {
			position: absolute;
			top: -5px;
			left: -5px;
			right: -5px;
			bottom: -5px;
			z-index: -1;
			content: '';
			display: block;
			border-radius: 50%;
			background-color: white;
		}
	`}

  ${({ large, theme }) =>
    large &&
    `
		width: 80px;
		height: 80px;
	`}
`;

const ActionBtnLabel = styled.div`
  font-size: 12px;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  color: white;
  margin-top: 10px;
  text-transform: uppercase;
`;

const ProDot = styled.span`
  position: absolute;
  top: -3px;
  right: -3px;
  z-index: 1;
  width: 18px;
  height: 18px;
  border-radius: 10px;
  border: 2px solid white;
  background-color: ${(props) => props.theme.colors.secondary};
`;

export default ClipPreview;
