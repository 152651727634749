import {
  format,
  differenceInYears,
  parseISO,
  addDays,
  differenceInDays,
} from 'date-fns';

export const formatDateRange = (dates) => {
  if (!dates) return '';

  const formatted = (date) => format(parseISO(date), 'M/d/yy');
  if (typeof dates === 'string') return formatted(dates);

  const appended =
    dates.end && dates.end !== dates.start ? ` - ${formatted(dates.end)}` : '';
  return `${formatted(dates.start)} ${appended}`;
};

export const formatToPhone = (phone) => {
  if (!phone) return '';

  const value = phone.replace(/[^0-9]/g, '');
  const len = value.length;
  if (len === 0) return value;
  else if (len === 3) return '(' + value.substring(0, 3) + ') ';
  else if (len < 3) return '(' + value;
  else if (len < 6)
    return '(' + value.substring(0, 3) + ') ' + value.substring(3);
  else if (len === 6)
    return '(' + value.substring(0, 3) + ') ' + value.substring(3, 6) + '-';
  else if (len > 6)
    return (
      '(' +
      value.substring(0, 3) +
      ') ' +
      value.substring(3, 6) +
      '-' +
      value.substring(6, 10)
    );
  return value;
};

export const formatCommaSeparatedList = (items) => items.join(', ');

// helper function to transform 'Billy Madison' --> 'billymadison'
export const reduceToTagFormat = (str) =>
  str.toLowerCase().replace(/[^0-9a-zA-Z]/g, '');

export const findObjectByProperty = (obj, key, value, returnKey) => {
  // find in master object where property === value (optionally return only key)
  if (!obj) return null;

  for (let prop in obj) {
    if (obj[prop][key] === value) return returnKey ? prop : obj[prop];
  }
};

export const findObjectInArray = (array, key, value) => {
  // 1. takes an array of objects
  // 2. finds matching object for key/value pair
  // 3. returns match
  if (!array) return null;

  let obj = array.find((o) => o[key] === value);

  return obj;
};

export const findObjectIndexInArray = (array, key, value) => {
  // 1. takes an array of objects
  // 2. finds matching object for key/value pair
  // 3. returns index
  for (let i = 0; i < array.length; i += 1) {
    if (array[i][key] === value) {
      return i;
    }
  }
  return -1;
};

export const groupBy = (key) => {
  // returns a function that should be called using the data to group
  // example:
  //    const groupByCity = groupBy('city');
  //    const groupedData = groupByCity(data);
  //    console.log(groupedData)
  //    // expected return
  //    // [ 'akron': [...], 'canton': [...], 'cleveland': [...] ]
  return (array) => {
    return array.reduce((acc, obj) => {
      const property = obj[key];
      acc[property] = acc[property] || [];
      acc[property].push(obj);
      return acc;
    }, []);
  };
};

export const getDateCountdown = (date) => {
  if (!date) return null;

  const targetDate = addDays(date, 60);
  return Math.ceil(differenceInDays(new Date(), targetDate));
};

export const getAge = (dob) => {
  // calculate age from DOB
  const birthdate = typeof dob === 'object' ? dob : parseISO(dob);
  return dob ? differenceInYears(new Date(), birthdate) : 'n/a';
};

export const capitalize = (str) => {
  return str ? str.charAt(0).toUpperCase() + str.slice(1).toLowerCase() : str;
};

export const resolvePath = (object, path, defaultValue) =>
  path.split('.').reduce((o, p) => (o ? o[p] : defaultValue), object);

export const padDigit = (num) => ('0' + num).slice(-2);

const getTimeCounts = (time) => {
  const value = time ? Number(time).toFixed(1) : 0;
  const decimal = (value % 1).toFixed(1);
  const numSeconds = parseInt(value, 10);

  const hours = Math.floor(numSeconds / 3600);
  const minutes = Math.floor((numSeconds - hours * 3600) / 60);
  const seconds = numSeconds - hours * 3600 - minutes * 60;

  return { hours, minutes, seconds, decimal };
};

export const toTimeString = (time, showMilliSeconds = true) => {
  const sec = parseFloat(time);
  const hours = padDigit(Math.floor(sec / 3600));
  const minutes = padDigit(Math.floor((sec - hours * 3600) / 60));
  const seconds = sec - hours * 3600 - minutes * 60;

  let maltissaRegex = /\..*$/; // matches the decimal point and the digits after it e.g if the number is 4.567 it matches .567
  let millisec = String(seconds).match(maltissaRegex);
  return (
    hours +
    ':' +
    minutes +
    ':' +
    String(seconds).replace(maltissaRegex, '') +
    (showMilliSeconds ? (millisec ? millisec[0] : '.000') : '')
  );
};

export const formatTimestamp = (time, omitDecimal) => {
  if (time === undefined) return null;

  const t = getTimeCounts(time);

  let timestamp = padDigit(t.minutes) + ':' + padDigit(t.seconds);
  if (t.hours > 0) timestamp = padDigit(t.hours) + ':' + timestamp;
  if (!omitDecimal) timestamp = timestamp + String(t.decimal).substring(1, 3);

  return timestamp;
};

export const convertTimeToFields = (time) => {
  const t = getTimeCounts(time);

  return {
    h1: t.hours < 10 ? 0 : Number(String(t.hours).charAt(0)),
    h2: Number(String(t.hours).charAt(String(t.hours.length - 1))),
    m1: t.minutes < 10 ? 0 : Number(String(t.minutes).charAt(0)),
    m2: Number(String(t.minutes).charAt(String(t.minutes.length - 1))),
    s1: t.seconds < 10 ? 0 : Number(String(t.seconds).charAt(0)),
    s2: Number(String(t.seconds).charAt(String(t.seconds).length - 1)),
  };
};

export const timestampToSeconds = (timestamp) => {
  let p = timestamp.split(':'),
    s = 0,
    m = 1;

  while (p.length > 0) {
    s += m * parseInt(p.pop(), 10);
    m *= 60;
  }

  return s;
};

export const convertToYesNo = (bool) => (bool ? 'Yes' : 'No');

export const convertLineBreaks = (str) => str.replaceAll('\n', '<br />');

export const getSourceName = (source) => {
  if (!source) return 'None';

  const host = source.toLowerCase();

  if (host.includes('youtube') || host.includes('youtu.be')) return 'YouTube';
  if (host.includes('twitter') || host.includes('t.co')) return 'Twitter';
  if (host.includes('instagram')) return 'Instagram';
  if (host.includes('facebook') || host.includes('fb.watch')) return 'Facebook';
  if (host.includes('tiktok')) return 'TikTok';
  if (host.includes('vimeo')) return 'Vimeo';
  if (host.includes('snapchat')) return 'Snapchat';
  if (host.includes('whatsapp')) return 'WhatsApp';
  if (host.includes('vine')) return 'Vine';
  if (host.includes('byte')) return 'Byte';
  if (host.includes('vlipsy') || host.includes('vlp.to')) return 'Vlipsy';
  if (host.includes('clipbox')) return 'Clipbox';
  if (host.includes('ph://')) return 'Camera Roll';

  // need to add one for Camera Roll

  return host;
};

export const hyphenate = (txt) => txt.toLowerCase().replace(/\s+/, '-');

export const unhyphenate = (txt) => txt.replace(/-/g, ' ');
