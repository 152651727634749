// @flow
import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { darken } from 'polished';
import * as Realm from 'realm-web';
import { EJSON } from 'bson';
import { format } from 'date-fns';
import { MdClose } from 'react-icons/md';
import Button from '../common/Button';
import UserIcon from '../common/UserIcon';
import { SystemContext } from '../../contexts/SystemContext';
import { APP_ID, CANCEL_URL, ENV } from '../../../constants';

const realm = Realm.App.getApp(APP_ID);

const AccountModal = ({ onDismiss }) => {
  const {
    state: { realmUser },
  } = useContext(SystemContext);
  const customData = EJSON.deserialize(realmUser.customData);

  const subscription = customData?.subscription;

  const [showDelete, setShowDelete] = useState(false);
  const [deleteWithSubscription, setDeleteWithSubscription] = useState(false);

  const onContinueDeletion = () => {
    setShowDelete(true);
    setDeleteWithSubscription(false);
  };

  const onDeleteClick = () => {
    if (subscription?.isValid) setDeleteWithSubscription(true);
    else setShowDelete(true);
  };

  const onDeleteAccount = async () => {
    await realm.deleteUser(realm.currentUser);
    onLogout();
  };

  const onCancelSubscription = async () => {
    const provider = subscription?.provider?.toLowerCase();
    const platform = subscription?.provider === 'google' ? 'android' : 'ios';

    let cancelURL = CANCEL_URL[platform];

    if (provider === 'stripe') {
      // Stripe subscriptions require a custom URL to be fetched
      cancelURL = await realmUser.functions.getStripePortalUrl(ENV);

      if (typeof cancelURL !== 'string') {
        // no subscription found on server, refresh data to update UI
        await realmUser.refreshCustomData();
      }
    }

    const TARGET = provider === 'stripe' ? '_self' : '_blank';
    window.open(cancelURL, TARGET);
  };

  const onUpgrade = () => {
    window.location = '/upgrade';
  };

  const onLogout = () => {
    window.location = '/logout';
  };

  return (
    <Wrapper>
      <Header>
        <Title>My Account</Title>
        <CloseBtn onClick={onDismiss}>
          <MdClose />
        </CloseBtn>
      </Header>
      <Content disabledScroll={showDelete}>
        <Section>
          <Row>
            <UserInfo>
              <UserIcon user={realmUser} size="48px" />
              <UserName>
                <Name>
                  {realmUser?.profile?.firstName} {realmUser?.profile?.lastName}
                </Name>
                <Email>{realmUser?.profile?.email}</Email>
              </UserName>
            </UserInfo>
            <Button onClick={onLogout}>Log Out</Button>
          </Row>
        </Section>
        <Section>
          <Title>Subscription</Title>
          {subscription?.isValid ? (
            <p>
              You are currently subscribed to{' '}
              <b>
                Clipbox PRO{' '}
                {subscription.productId.indexOf('monthly') > -1
                  ? 'Monthly'
                  : 'Yearly'}
              </b>
              . Your subscription will{' '}
              {subscription.finishDate ? 'expire' : 'renew'} on
              <b>
                {' '}
                {format(
                  subscription.finishDate || subscription.endDate,
                  'MMMM d',
                )}
              </b>
              .
            </p>
          ) : (
            <p>
              You are currently on the Free plan. Remove ads and unlock
              unlimited storage by upgrading your plan.
            </p>
          )}
          <ButtonRow>
            <Button
              primary={!subscription?.isValid}
              onClick={
                subscription?.isValid ? onCancelSubscription : onUpgrade
              }>
              {subscription?.isValid ? 'Cancel Subscription' : 'Upgrade to PRO'}
            </Button>
          </ButtonRow>
        </Section>
        <Section>
          <Title>Delete Account</Title>
          <p>Permanently delete your account and all of your content.</p>
          <ButtonRow>
            <Button secondary onClick={onDeleteClick}>
              Delete My Account
            </Button>
          </ButtonRow>
        </Section>
        <Footer>
          <a href="https://clipbox.app/support" target="_blank">
            Help
          </a>
          <a href="https://clipbox.app/terms" target="_blank">
            Terms
          </a>
          <a href="https://clipbox.app/privacy" target="_blank">
            Privacy
          </a>
        </Footer>

        {showDelete ? (
          <DeletePrompt>
            <Section>
              <Title>Delete account?</Title>
              <p>Are you sure you want to delete your account?</p>
              <p>
                Deleting your account will <b>permanently delete</b> ALL clips
                you've created.
              </p>
              <p>
                This cannot be undone. You will not be able to recover these
                clips.
              </p>
              <ButtonRow>
                <Button secondary large onClick={onDeleteAccount}>
                  Yes, Delete Account
                </Button>
                <Button large onClick={() => setShowDelete(false)}>
                  Cancel
                </Button>
              </ButtonRow>
            </Section>
          </DeletePrompt>
        ) : null}

        {deleteWithSubscription ? (
          <DeletePrompt>
            <Section>
              <Title>Active Subscription Found</Title>
              <p>
                You should cancel your subscription before deleting your
                account.
              </p>

              <ButtonColumn>
                <Button secondary large onClick={onContinueDeletion}>
                  Continue with Deletion
                </Button>
                <Button outlined large onClick={onCancelSubscription}>
                  Cancel My Subscription
                </Button>
                <Button large onClick={() => setDeleteWithSubscription(false)}>
                  Cancel
                </Button>
              </ButtonColumn>
            </Section>
          </DeletePrompt>
        ) : null}
      </Content>
    </Wrapper>
  );
};

const Title = styled.h3`
  font-size: 22px;
  color: ${(props) => props.theme.textColors.primary};
  flex: 1;
`;

const Section = styled.div`
  border-bottom: 2px solid ${(props) => props.theme.colors.border};
  padding: 2rem;

  ${Title} {
    font-size: 18px;
    margin-bottom: 10px;
  }

  p {
    font-size: 15px;
    line-height: 1.4;

    a {
      color: ${(props) => props.theme.colors.primary};
    }
  }

  @media only screen and (max-width: 768px) {
    padding: 1.5rem 1rem;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  @media only screen and (max-width: 768px) {
    flex-wrap: wrap;

    button {
      margin-top: 16px;
      width: 100%;
    }
  }
`;

const UserInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
`;

const UserName = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
`;

const Name = styled.div`
  font-size: 17px;
  color: ${(props) => props.theme.textColors.primary};
  font-weight: bold;
  line-height: 1.3;
  margin-bottom: 2px;
`;

const Email = styled.div`
  font-size: 14px;
  color: ${(props) => props.theme.textColors.secondary};
  line-height: 1.3;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  height: 100%;

  @media only screen and (max-width: 768px) {
    border-radius: 0;
  }
`;

const Header = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  background-color: white;
  padding: 1rem 2rem;
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.colors.border};

  @media only screen and (max-width: 768px) {
    padding: 1rem;
  }
`;

const CloseBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: flex-end;
  background: none;

  svg {
    color: ${(props) => props.theme.textColors.secondary};
    font-size: 24px;
  }

  &:hover {
    svg {
      color: ${(props) => props.theme.textColors.primary};
    }
  }
`;

const Content = styled.div`
  position: relative;
  width: 100%;
  flex: 1;
  overflow-y: ${(props) => (props.disabledScroll ? 'hidden' : 'scroll')};
  pointer-events: auto;
`;

const ButtonRow = styled(Row)`
  margin-top: 16px;

  @media only screen and (max-width: 768px) {
    margin-top: 0;
  }
`;

const ButtonColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  padding: 0 60px;

  button {
    margin: 10px;
  }

  @media only screen and (max-width: 768px) {
    padding: 0;
  }
`;

const Footer = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  a {
    display: block;
    padding: 6px 12px;
    font-size: 13px;
    font-weight: bold;
    color: ${(props) => props.theme.textColors.secondary};
    cursor: pointer;

    &:hover {
      color: ${(props) => darken(0.1, props.theme.textColors.secondary)};
    }
  }
`;

const DeletePrompt = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;

  ${Section} {
    text-align: center;
    padding: 0 40px;
    border-bottom: none;

    ${Title} {
      font-size: 22px;
      margin-bottom: 24px;
    }

    p {
      font-size: 17px;
      margin-bottom: 16px;
    }
  }

  ${ButtonRow} {
    margin-top: 40px;
    justify-content: center;

    button {
      margin: 0 8px;
    }
  }

  @media only screen and (max-width: 768px) {
    ${Section} {
      padding: 2rem 1rem;

      p {
        font-size: 16px;
      }
    }

    ${ButtonRow} {
      button {
        margin-top: 16px;
      }
    }
  }
`;

export default AccountModal;
