import { useContext } from 'react';
import * as Realm from 'realm-web';
import { FaLink, FaCheck, FaExclamationCircle } from 'react-icons/fa';
import { MdCheckCircle } from 'react-icons/md';
import { AlertContext } from '../contexts/AlertContext';
import { ClipsContext } from '../contexts/ClipsContext';
import { ModalContext } from '../contexts/ModalContext';
import ConfirmModal from '../components/modals/ConfirmModal';
import EditModal from '../components/modals/EditModal';
import PlaylistModal from '../components/modals/PlaylistModal';
import ShareLinkModal from '../components/modals/ShareLinkModal';
import UpgradeModal from '../components/modals/UpgradeModal';
import useAmplitude from './useAmplitude';
import { APP_ID } from '../../constants';

const realm = Realm.App.getApp(APP_ID);

const useClipActions = (config) => {
  const { dispatch: dispatchAlert } = useContext(AlertContext);
  const {
    state: { preview },
    dispatch: dispatchClips,
  } = useContext(ClipsContext);
  const { dispatch: dispatchModal } = useContext(ModalContext);

  const { trackEvent, setUserProperty } = useAmplitude();

  const onError = (message) => {
    dispatchAlert({
      type: 'ADD_ALERT',
      data: {
        type: 'warn',
        message,
        icon: FaExclamationCircle,
      },
    });
  };

  // ----- FETCH FILE -----

  const fetchFile = async () => {
    const res = await fetch(config.clip);
    const file = await res.blob();
    return file;
  };

  // ----- SHARE SHEET -----

  const shareClip = async (onFinish) => {
    let blob;

    try {
      blob = await fetchFile();
    } catch (err) {
      onError('Error fetching file from server.');
      if (onFinish) onFinish();
      return;
    }

    const shareData = {
      files: [
        new File([blob], `${config._id.toString()}.mp4`, {
          type: blob.type,
        }),
      ],
    };

    try {
      if (!navigator.canShare || !navigator.canShare(shareData)) {
        onError('Sharing is not supported on this browser.');
        if (onFinish) onFinish();
        return;
      }
    } catch (err) {
      onError('Sharing is not supported on this browser.');
      if (onFinish) onFinish();
      return;
    }

    try {
      await navigator.share(shareData);
      dispatchAlert({
        type: 'ADD_ALERT',
        data: {
          message: 'Clip Shared',
          type: 'toast',
          icon: FaCheck,
        },
      });
    } catch (err) {
      onError(err.message);
    }
    if (onFinish) onFinish();
  };

  // ----- DOWNLOAD -----

  const downloadClip = async () => {
    try {
      const file = await fetchFile();
      const tempUrl = URL.createObjectURL(file);
      const aTag = document.createElement('a');
      aTag.href = tempUrl;

      const filename =
        config?.title?.length > 0
          ? config.title
              .replace(/[^a-zA-Z ]/g, '')
              .split(' ')
              .join('-')
              .toLowerCase()
          : config._id;
      aTag.download = `clipbox-${filename}`;

      document.body.appendChild(aTag);
      aTag.click();
      URL.revokeObjectURL(tempUrl);
      aTag.remove();
    } catch (err) {
      console.log('Error downloading video: ', err);
      onError('Error fetching file from server.');
    }
  };

  // ----- COPY LINK -----

  const copyLink = (onFinish) => {
    const onCopy = (shareId) => {
      const link = `https://clipbox.app/${shareId}`;
      navigator.clipboard.writeText(link);
      dispatchAlert({
        type: 'ADD_ALERT',
        data: {
          message: 'Link Copied to Clipboard',
          type: 'toast',
          icon: FaLink,
        },
      });
    };

    if (config.shareId) {
      onCopy(config.shareId);
    } else {
      dispatchModal({
        type: 'ADD_MODAL',
        data: {
          component: ShareLinkModal,
          props: {
            clip: config,
            onCopy,
            maxWidth: '600px',
            borderRadius: '20px',
          },
          onDismiss: onFinish,
        },
      });
    }
  };

  // ----- EDIT CLIP -----

  const editClip = (onFinish) => {
    dispatchModal({
      type: 'ADD_MODAL',
      data: {
        component: EditModal,
        props: {
          config,
          maxWidth: '600px',
          borderRadius: '20px',
        },
        onDismiss: onFinish,
      },
    });
  };

  // ----- UPDATE Clip -----

  const updateClip = async (id, formData, changeLog, onFinish) => {
    try {
      await realm.currentUser.functions.updateClipInfo(id, formData);

      // log change event in Amplitude
      trackEvent('Clip Edited', {
        'Clip ID': id,
        'Title Changed': changeLog.title ? 'Yes' : 'No',
        'Tags Changed': changeLog.tags ? 'Yes' : 'No',
      });

      const isFromPreview = preview?._id.toString() === id;
      if (isFromPreview) {
        // update the preview before navigating back from editModal
        dispatchClips({
          type: 'SET_PREVIEW',
          data: {
            ...preview,
            ...formData,
          },
        });
      } else {
        // tell any lists to update when user navigates back to them
        dispatchClips({ type: 'CLEAR' });
      }

      // show success to user
      dispatchAlert({
        type: 'ADD_ALERT',
        data: {
          message: 'Clip Updated',
          type: isFromPreview ? null : 'toast',
          icon: MdCheckCircle,
        },
      });

      onFinish();
    } catch (err) {
      throw err;
    }
  };

  // ----- DELETE CLIP -----

  const deleteClip = () => {
    dispatchModal({
      type: 'ADD_MODAL',
      data: {
        component: ConfirmModal,
        onConfirm: confirmDelete,
        props: {
          title: 'Delete Clip?',
          description:
            'Are you sure you want to delete this clip? Once deleted, it will be gone forever and no longer available via any shared links.',
          confirmLabel: 'Delete clip',
          confirmIsDestructive: true,
          maxWidth: '480px',
          borderRadius: '20px',
        },
      },
    });
  };

  const confirmDelete = async () => {
    const clipCount = realm.currentUser?.customData?.clipCount;

    // track deletion in Amplitude
    trackEvent('Clip Deleted', {
      'Clip ID': config._id.toString(),
      'Clip Count': clipCount,
    });

    // delete it
    await realm.currentUser.functions.deleteClip(config._id.toString());

    // update Amplitude user record
    if (clipCount && clipCount > 0)
      setUserProperty({ 'Clips Created': clipCount - 1 });

    // refresh list
    dispatchClips({
      type: 'CLEAR',
    });
  };

  // ------- ADD TO PLAYLIST --------

  const addToPlaylist = () => {
    dispatchModal({
      type: 'ADD_MODAL',
      data: {
        component: PlaylistModal,
        props: {
          clip: config,
          maxWidth: '600px',
          borderRadius: '20px',
        },
      },
    });
  };

  // ------- REMOVE FROM PLAYLIST -------

  const removeFromPlaylist = async (playlist) => {
    const PLAYLIST_ID = playlist._id.toString();

    const result = await realm.currentUser.functions.removeFromPlaylist(
      PLAYLIST_ID,
      config._id.toString(),
    );
    console.log(result);

    // refresh playlist info
    const _playlist = await realm.currentUser.functions.getPlaylistInfo(
      PLAYLIST_ID,
    );
    dispatchClips({
      type: 'SET_LIST',
      data: {
        title: _playlist.title,
        count: _playlist.count,
        list: _playlist.clips,
        type: 'playlist',
        playlist: _playlist,
      },
    });
  };

  // ------ REQUIRES PRO FEATURE ------

  const requiresPro = (mode, onFinish) => {
    dispatchModal({
      type: 'ADD_MODAL',
      data: {
        component: UpgradeModal,
        props: {
          mode,
          maxWidth: '600px',
          borderRadius: '20px',
        },
        onDismiss: onFinish,
      },
    });
  };

  return {
    downloadClip,
    copyLink,
    deleteClip,
    editClip,
    updateClip,
    addToPlaylist,
    removeFromPlaylist,
    shareClip,
    requiresPro,
  };
};

export default useClipActions;
