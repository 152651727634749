// @flow
import React from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import AlertContextProvider from './contexts/AlertContext';
import ClipsContextProvider from './contexts/ClipsContext';
import CreatorContextProvider from './contexts/CreatorContext';
import ModalContextProvider from './contexts/ModalContext';
import PlaylistsContextProvider from './contexts/PlaylistsContext';
import PopoverContextProvider from './contexts/PopoverContext';
import SystemContextProvider from './contexts/SystemContext';

import { GOOGLE_CLIENT_ID } from '../constants';

const Root = ({ children }) => (
  <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
    <SystemContextProvider>
      <PlaylistsContextProvider>
        <ClipsContextProvider>
          <CreatorContextProvider>
            <ModalContextProvider>
              <PopoverContextProvider>
                <AlertContextProvider>{children}</AlertContextProvider>
              </PopoverContextProvider>
            </ModalContextProvider>
          </CreatorContextProvider>
        </ClipsContextProvider>
      </PlaylistsContextProvider>
    </SystemContextProvider>
  </GoogleOAuthProvider>
);

export default Root;
