import React, { useState } from 'react';
import styled from 'styled-components';
import * as Realm from 'realm-web';
import { APP_ID, ENV } from '../../../constants';
import ActivityIndicator from '../common/ActivityIndicator';

const realm = Realm.App.getApp(APP_ID);

const Upgrade = () => {
  const [planType, setPlanType] = useState('yearly');
  const [loading, setLoading] = useState(false);

  const onUpgradeClick = async () => {
    setLoading(true);

    const stripeUrl = await realm.currentUser.functions.getStripeCheckoutUrl(
      planType,
      ENV,
    );
    window.location.replace(stripeUrl);
  };

  return (
    <Container>
      <Section>
        <Title>
          <span>Upgrade to Clipbox PRO</span>
          <span>to get unlimited access to everything</span>
        </Title>
      </Section>
      <ToggleSection>
        <h2>Pay</h2>
        <ToggleWrapper>
          <ToggleBtn
            onClick={() => setPlanType('yearly')}
            disabled={planType === 'yearly'}>
            Yearly
            {planType === 'yearly' ? <span>Save 37%</span> : null}
          </ToggleBtn>
          <ToggleBtn
            onClick={() => setPlanType('monthly')}
            disabled={planType === 'monthly'}>
            Monthly
          </ToggleBtn>
        </ToggleWrapper>
      </ToggleSection>
      <PlanSection>
        <PlanCard>
          <div>
            <h3>{planType === 'yearly' ? '$2.50' : '$4'}</h3>
            <label>{`per month, billed ${planType}`}</label>
            <UpgradeBtn onClick={onUpgradeClick} disabled={loading}>
              {loading ? (
                <ActivityIndicator size={18} color="white" />
              ) : (
                'Upgrade'
              )}
            </UpgradeBtn>
          </div>
          <div>
            <ul>
              <li>Unlimited Clip Storage</li>
              <li>Extended Clips: Up to 1 minute</li>
              <li>Organize clips into playlists</li>
              <li>Custom thumbnails</li>
              <li>No ads</li>
            </ul>
          </div>
        </PlanCard>
      </PlanSection>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding-top: 80px;
  background-color: white;

  @media only screen and (max-width: 768px) {
    padding-top: 64px;
  }
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  padding: 0 24px;
`;

const ToggleSection = styled(Section)`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 64px;

  h2 {
    margin-right: 20px;
    font-size: 28px;
  }

  @media only screen and (max-width: 768px) {
    padding-top: 48px;

    h2 {
      margin-right: 12px;
      font-size: 20px;
    }
  }
`;

const Title = styled.h1`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${(props) => props.theme.textColors.primary};
  text-align: center;
  font-size: 52px;
  font-weight: bold;
  line-height: 68px;
  letter-spacing: -0.02em;

  span {
    display: block;

    &:last-child {
      font-size: 36px;
      font-weight: 600;
      line-height: 44px;
      margin-top: 32px;
    }
  }

  @media only screen and (max-width: 768px) {
    font-size: 32px;
    line-height: 1;

    span:last-child {
      font-size: 22px;
      margin-top: 12px;
    }
  }
`;

const ToggleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.border};
  padding: 10px;
  border-radius: 20px;

  @media only screen and (max-width: 768px) {
    padding: 6px;
    border-radius: 16px;
  }
`;

const ToggleBtn = styled.button`
  position: relative;
  outline: none;
  border: none;
  color: ${(props) => props.theme.textColors.primary};
  font-size: 28px;
  font-weight: bold;
  background-color: transparent;
  border-radius: 16px;
  padding: 10px 30px;
  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    `
		background-color: white;
		pointer-events: none;
	`}

  span {
    display: block;
    position: absolute;
    bottom: -40px;
    color: ${(props) => props.theme.colors.green};
    font-size: 1rem;
  }

  @media only screen and (max-width: 768px) {
    font-size: 20px;
    border-radius: 14px;

    span {
      font-size: 16px;
    }
  }
`;

const PlanSection = styled(Section)`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-bottom: 120px;
`;

const PlanCard = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 640px;
  min-width: 320px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(85, 116, 162, 0.2);
  border-radius: 20px;
  border-top: 12px solid ${(props) => props.theme.colors.primary};
  margin-top: 72px;

  h3 {
    font-size: 36px;
    line-height: 44px;
    font-weight: bold;
    margin: 0 0 12px;
    color: ${(props) => props.theme.textColors.primary};
  }
  label {
    font-size: 15px;
    color: ${(props) => props.theme.textColors.secondary};
    text-transform: none;
    letter-spacing: 0;
    margin: 0;
  }
  ul {
    padding-left: 16px;
  }
  li {
    list-style-type: disc;
    color: ${(props) => props.theme.textColors.secondary};
    font-size: 16px;
  }

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px;

    &:first-child {
      border-right: 1px solid ${(props) => props.theme.colors.border};
      min-width: 236px;
    }
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;

    > div:first-child {
      border-right: none;
      border-bottom: 1px solid ${(props) => props.theme.colors.border};
    }
  }
`;

const UpgradeBtn = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  color: white;
  font-size: 20px;
  font-weight: bold;
  background-color: ${(props) => props.theme.colors.secondary};
  border-radius: 16px;
  padding: 12px 32px;
  cursor: pointer;
  margin-top: 24px;
  min-height: 60px;

  @media only screen and (max-width: 768px) {
    font-size: 16px;

    > div:first-child {
      border: none !important;
    }
  }
`;

export default Upgrade;
