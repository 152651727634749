import React, { useRef, useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import * as Realm from 'realm-web';
import { useLocation } from 'react-router-dom';
import { RiSearchLine } from 'react-icons/ri';
import { ClipsContext } from '../../contexts/ClipsContext';
import { SystemContext } from '../../contexts/SystemContext';
import useOutsideClick from '../../hooks/useOutsideClick';
import { APP_ID } from '../../../constants';

const realm = Realm.App.getApp(APP_ID);

const SearchBar = ({
  white,
  autofocus,
  mobile,
  placeholder = 'Search Clipbox',
  onFocusChange,
}) => {
  const inputRef = useRef();

  const {
    state: { type: listType },
    dispatch: dispatchClips,
  } = useContext(ClipsContext);
  const { dispatch: dispatchSystem } = useContext(SystemContext);
  const searchHistory = realm?.currentUser?.customData?.searchHistory;
  const location = useLocation();
  const outsideRef = useOutsideClick(() => setIsFocused(false));

  const [isFocused, setIsFocused] = useState();
  const [searchTerm, setSearchTerm] = useState('');

  const onUpdateText = (e) => setSearchTerm(e.target.value);

  const onFocusInput = () => {
    setIsFocused(true);
  };

  const refreshUser = async () => {
    await realm.currentUser.refreshCustomData();

    // keep session instance synced with realm
    dispatchSystem({
      type: 'SET_SYSTEM',
      data: {
        realmUser: realm.currentUser,
      },
    });
  };

  const onClearHistory = async () => {
    await realm.currentUser.functions.saveSearchHistory([]);
    refreshUser();
  };

  const onClearInput = () => {
    setSearchTerm('');
    if (mobile && listType === 'search') {
      dispatchClips({ type: 'CLEAR' });
    }
  };

  const onTagClick = (e, term) => {
    e.stopPropagation();
    setSearchTerm(term);
    onSearch(term, true);
  };

  const saveToSearchHistory = async (term) => {
    let history = searchHistory || [];

    // check to see if vlip already exists in recents
    const termIndex = history ? history.indexOf(term.toLowerCase()) : -1;

    // if it exists, move to the beginning, otherwise keep list to max of 20
    if (termIndex > -1) history.splice(termIndex, 1);
    history = [term.toLowerCase(), ...history.slice(0, 18)];

    await realm.currentUser.functions.saveSearchHistory(history);
    refreshUser();
  };

  const onSearch = async (term, isTag) => {
    // if not searching from home page, redirect back to home page
    if (location.pathname !== '/') {
      window.location = '/?q=' + term;
      return;
    }

    const clipResults = await realm.currentUser.functions.searchClips(term);
    dispatchClips({
      type: 'SET_LIST',
      data: {
        title: term,
        count: clipResults.length,
        list: clipResults,
        type: 'search',
      },
    });
    // clicking a tag is not a deliberate search, skip saving to searchHistory
    if (!isTag) saveToSearchHistory(term);
    if (!mobile) setSearchTerm('');
    inputRef.current.blur();
    setIsFocused(false);
  };

  const onKeyDown = (e) => {
    const term = e.target.value;
    if (term.length < 3) return;

    // submit on enter
    if (e.keyCode === 13) onSearch(term);
  };

  useEffect(() => {
    if (onFocusChange) onFocusChange(isFocused);
  }, [isFocused]);

  useEffect(() => {
    // focus search field automatically
    if (autofocus && inputRef.current) inputRef.current.focus();
  }, [autofocus, inputRef]);

  useEffect(() => {
    if (location.search) {
      const urlParams = new URLSearchParams(location.search);
      const query = urlParams.get('q');
      if (query) onSearch(query);
    }
  }, []);

  return (
    <Wrapper ref={outsideRef} mobile={mobile}>
      <SearchWrapper>
        <SearchInputWrapper white={white}>
          <RiSearchLine />
          <SearchInput
            ref={inputRef}
            placeholder={placeholder}
            onChange={onUpdateText}
            onFocus={onFocusInput}
            onKeyDown={onKeyDown}
            value={searchTerm}
          />
          <ClearBtn isActive={searchTerm.length > 2} onClick={onClearInput}>
            <svg
              focusable="false"
              height="24px"
              viewBox="0 0 24 24"
              width="24px"
              xmlns="http://www.w3.org/2000/svg">
              <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
              <path d="M0 0h24v24H0z" fill="none"></path>
            </svg>
          </ClearBtn>
        </SearchInputWrapper>
        {mobile && isFocused ? (
          <CancelBtn onClick={() => setIsFocused()}>Cancel</CancelBtn>
        ) : null}
      </SearchWrapper>
      {isFocused && searchHistory?.length > 0 ? (
        <SearchHistory id="searchHistory">
          <HistoryHeader>
            <HistoryTitle>Recent Searches</HistoryTitle>
            <HistoryClearBtn onClick={onClearHistory}>Clear</HistoryClearBtn>
          </HistoryHeader>
          <HistoryList>
            {searchHistory.map((item) => (
              <HistoryItem key={item} onClick={(e) => onTagClick(e, item)}>
                <span>{item}</span>
                <RiSearchLine />
              </HistoryItem>
            ))}
          </HistoryList>
        </SearchHistory>
      ) : null}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  flex-grow: 2;
  margin-right: 4rem;
  max-width: 680px;

  @media only screen and (max-width: 768px) {
    margin-right: ${(props) => (props.mobile ? '0' : '1rem')};
  }
`;

const SearchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SearchHistory = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  max-height: 300px;
  border-radius: 10px 10px 20px 20px;
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 0 1px 4px rgba(85, 116, 162, 0.2);

  @media only screen and (max-width: 768px) {
    position: fixed;
    top: 150px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 50;
    max-height: initial;
    background-color: ${(props) => props.theme.backgroundColors.primary};
    border-radius: 0;
  }
`;

const HistoryHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 14px 20px;
`;

const HistoryTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: ${(props) => props.theme.textColors.primary};
  flex: 1;
`;

const HistoryClearBtn = styled.button`
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0 10px;
  border-radius: 20px;
  font-size: 13px;
  color: white;
  background-color: rgba(${(props) => props.theme.textColors.primaryRGB}, 0.25);

  height: 28px;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  padding: 0 12px;
`;

const HistoryList = styled.div`
  flex: 1;
  overflow-y: scroll;
  padding: 0 15px 10px;

  @media only screen and (max-width: 768px) {
    padding: 0 12px 84px;
  }
`;

const HistoryItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.colors.border};
  padding: 10px 12px;
  background-color: ${(props) => props.theme.backgroundColors.secondary};
  font-size: 15px;
  font-weight: 600;
  color: ${(props) => props.theme.textColors.primary};
  cursor: pointer;

  span {
    flex: 1;
  }

  svg {
    color: ${(props) => props.theme.textColors.secondary};
    font-size: 17px;
  }

  &:hover {
    color: ${(props) => props.theme.colors.primary};

    svg {
      color: ${(props) => props.theme.colors.primary};
    }
  }

  @media only screen and (min-width: 769px) {
    &:last-of-type {
      border-bottom: none;
    }
  }

  @media only screen and (max-width: 768px) {
    background-color: ${(props) => props.theme.backgroundColors.primary};
    padding: 16px 20px;
  }
`;

const SearchInputWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid transparent;
  background-color: ${(props) => (props.white ? 'white' : '#F1F3F4')};
  transition: background 0.1s ease-in, width 0.1s ease-out;
  flex: 1;

  svg {
    font-size: 18px;
    margin-right: 15px;
    margin-left: 15px;
    color: ${(props) => props.theme.textColors.secondary};
  }
`;

const SearchInput = styled.input`
  flex: 1;
  background-color: transparent;
  border: none;
  font-variant-ligatures: none;
  font-size: 16px;
  line-height: 1.8;
  color: ${(props) => props.theme.textColors.primary};
  outline: none;
  padding: 4px 0;
  font-family: ${(props) => props.theme.fonts.secondary};

  &::placeholder {
    color: ${(props) => props.theme.textColors.secondary};
  }
`;

const ClearBtn = styled.button`
  position: absolute;
  top: 4px;
  right: 4px;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  line-height: 0;
  padding: 0 5px;
  visibility: ${(props) => (props.isActive ? 'inherit' : 'hidden')};

  svg {
    padding: 4px;
    margin: 3px;
    color: #5f6368;
    opacity: 1;
  }

  &:hover {
    svg {
      background-color: rgba(60, 64, 67, 0.08);
      border-radius: 50%;
    }
  }

  @media only screen and (max-width: 768px) {
    svg {
      background-color: rgba(60, 64, 67, 0.08);
      border-radius: 50%;
    }
  }
`;

const CancelBtn = styled.button`
  color: ${(props) => props.theme.textColors.primary};
  font-size: 14px;
  font-weight: ${(props) => props.theme.fontWeights.medium};
  background-color: transparent;
  height: 38px;
  padding: 0 10px;
  margin-left: 5px;
  margin-right: -5px;
`;

export default SearchBar;
