// @flow
import React, { useEffect, useState, useContext, useRef } from 'react';
import styled from 'styled-components';
import * as Realm from 'realm-web';
import PlaylistItem from '../common/PlaylistItem';
import EditModal from '../modals/EditModal';
import { ClipsContext } from '../../contexts/ClipsContext';
import { ModalContext } from '../../contexts/ModalContext';
import { PlaylistsContext } from '../../contexts/PlaylistsContext';
import usePlaylistActions from '../../hooks/usePlaylistActions';
import { APP_ID } from '../../../constants';

const realm = Realm.App.getApp(APP_ID);

const PlaylistSelector = ({ isVisible, onClose }) => {
  const { dispatch: dispatchClips } = useContext(ClipsContext);
  const { dispatch: dispatchModal } = useContext(ModalContext);
  const {
    state: { starred, playlists },
  } = useContext(PlaylistsContext);
  const { fetchPlaylists } = usePlaylistActions();

  const onPlaylistCreate = () => {
    dispatchModal({
      type: 'ADD_MODAL',
      data: {
        component: EditModal,
        props: {
          isNew: true,
          isPlaylist: true,
        },
      },
    });
  };

  const onPlaylistClick = async (id) => {
    try {
      const playlist = await realm.currentUser.functions.getPlaylistInfo(id);
      dispatchClips({
        type: 'SET_LIST',
        data: {
          title: playlist.title,
          count: playlist.count,
          list: playlist.clips,
          type: 'playlist',
          playlist: playlist,
        },
      });
    } catch (err) {
      console.log('Playlist could not be loaded: ', err);
    }
    onClose();
  };

  useEffect(() => {
    fetchPlaylists();
  }, []);

  return (
    <Wrapper isVisible={isVisible}>
      <List empty={starred.length === 0 && playlists.length === 0}>
        <CreateBtn onClick={onPlaylistCreate}>Create Playlist</CreateBtn>

        {starred.length > 0 ? (
          <Group>
            <GroupTitle>Starred</GroupTitle>
            <GroupContent>
              {starred.map((obj) => (
                <PlaylistItem
                  key={obj._id.toString()}
                  config={obj}
                  starred
                  onClick={() => onPlaylistClick(obj)}
                />
              ))}
            </GroupContent>
          </Group>
        ) : null}

        {playlists.length > 0 ? (
          <Group>
            <GroupTitle>Playlists</GroupTitle>
            <GroupContent>
              {playlists.map((obj) => (
                <PlaylistItem
                  key={obj._id.toString()}
                  config={obj}
                  onClick={onPlaylistClick}
                />
              ))}
            </GroupContent>
          </Group>
        ) : null}
      </List>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  display: ${(props) => (props.isVisible ? 'flex' : 'none')};
  flex-direction: column;
  background-color: ${(props) => props.theme.backgroundColors.primary};
  flex: 1;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding-bottom: 96px;
`;

const CreateBtn = styled.div`
  background-color: ${(props) => props.theme.colors.primary};
  color: white;
  font-size: 16px;
  font-weight: ${(props) => props.theme.fontWeights.medium};
  height: 52px;
  border-radius: 48px;
  margin: 1.5rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const List = styled.div`
  width: 100%;
  flex: 1;

  ${({ empty }) =>
    empty &&
    `
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
	`}
`;

const Group = styled.div`
  padding-bottom: 1rem;

  &:last-child {
    padding: 0 !important;
  }
`;

const GroupTitle = styled.div`
  padding: 0 1rem 0.5rem;
  font-size: 14px;
  font-weight: ${(props) => props.theme.fontWeights.medium};
  color: ${(props) => props.theme.textColors.secondary};
  text-transform: uppercase;
`;

const GroupContent = styled.div`
  padding: 0 3px;
`;

export default PlaylistSelector;
