// @flow
import React, { useReducer, createContext } from 'react';

export const PopoverContext = createContext();

/**
 * Popover Context.
 *
 * Creates a modal object that's received and displayed by <PopoverManager>
 *
 * Modal object structure:
 * Expects component written as: ContextPopover  and not: <ContextPopover>  and not:  'ContextPopover'
 * @param {Node}      component               name of component to use in the modal, written as: ContextPopover
 * @param {Object}    props                   object of props for the component
 * @param {Function}  onDismiss               optional: function to run on dismiss button
 *
 */

const Context = ({ children }) => {
  const generateRandomID = () =>
    Math.random()
      .toString(36)
      .substr(2, 9);

  const reducer = (state, { type, data }) => {
    switch (type) {
      case 'ADD_POPOVER': {
        data.id = generateRandomID();
        return {
          ...state,
          ...data,
        };
      }
      case 'CLEAR': {
        return null;
      }
      default: {
        return state;
      }
    }
  };

  const [state, dispatch] = useReducer(reducer, null);

  return (
    <PopoverContext.Provider value={{ state, dispatch }}>
      {children}
    </PopoverContext.Provider>
  );
};

export default Context;
