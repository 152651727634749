// @flow
import React, { createContext, useReducer } from 'react';

const INITIAL_STATE = {
  starred: [],
  playlists: [],
};

export const PlaylistsContext = createContext(INITIAL_STATE);

const Context = ({ children }) => {
  const reducer = (state, { type, data }) => {
    switch (type) {
      case 'SET_PLAYLISTS': {
        return data;
      }
      case 'CLEAR': {
        return INITIAL_STATE;
      }
      default: {
        return state;
      }
    }
  };

  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <PlaylistsContext.Provider value={{ state, dispatch }}>
      {children}
    </PlaylistsContext.Provider>
  );
};

export default Context;
