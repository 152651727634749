import React, { useState, useEffect, useContext, useRef } from 'react';
import styled from 'styled-components';
import Modal from './Modal';
import { ModalContext } from '../../contexts/ModalContext';

const ModalManager = () => {
  const [renderCount, setRenderCount] = useState(0);
  const [isVisible, showModal] = useState(false);
  const { state: modal, dispatch: dispatchModal } = useContext(ModalContext);

  const target = useRef(null);

  useEffect(() => {
    let hasCurrent = target.current ? true : false;
    if (modal) {
      target.current = modal;
      showModal(true);
      // if modal is being replaced, rerender
      if (hasCurrent) setRenderCount((count) => count + 1);
    }
  }, [modal]);

  // animate out, pause, then remove from parent
  const closeModal = () => {
    // clear in context
    dispatchModal({ type: 'CLEAR' });
    showModal(false);
    target.current = null;
  };

  const onDismiss = (data) => {
    // if error gets thrown on next line, check that 'onDismiss' was passed to ModalContext
    // sometimes the click event passes the event object to onDismiss
    if (target?.current?.onDismiss) target.current.onDismiss(data);
    if (!target?.current?.replaceOnDismiss) closeModal();
  };

  const confirmClick = (data) => {
    if (target?.current?.onConfirm) target.current.onConfirm(data);
    closeModal();
  };

  const Component = modal ? modal.component : null;

  return (
    <Container
      show={isVisible}
      disabled={target?.current?.disableDismissOutside}>
      {isVisible && modal ? (
        <>
          <Cover
            visible={isVisible}
            onClick={() => onDismiss()}
            disabled={target.current.disableDismissOutside}
            transparent={modal?.props?.hideCover}
            coverColor={modal?.props?.coverColor}
            coverOpacity={modal?.props?.coverOpacity}
          />

          <Modal
            isVisible={isVisible}
            maxWidth={modal.props.maxWidth}
            fullHeight={modal.props.fullHeight}
            borderRadius={modal.props.borderRadius}>
            <Component
              onConfirm={confirmClick}
              onDismiss={onDismiss}
              {...target.current.props}
            />
          </Modal>
        </>
      ) : null}
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: ${(props) => (props.show ? 9999 : -1)};
  pointer-events: ${(props) => (props.disabled ? 'all' : 'none')};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8vh;
  display: ${(props) => (props.show ? 'flex' : 'none')};

  @media only screen and (max-width: 768px) {
    padding: 0;
  }
`;

const Cover = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${(props) =>
    props.coverColor
      ? props.coverColor
      : props.transparent
      ? 'transparent'
      : 'white'};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'all')};
  transition: opacity 0.2s ease-in;

  opacity: ${(props) => (props.visible ? props.coverOpacity || 0.8 : 0)};

  @media only screen and (max-width: 768px) {
    background-color: ${(props) =>
      props.coverColor
        ? props.coverColor
        : props.transparent
        ? 'transparent'
        : 'black'};
    opacity: ${(props) => (props.visible ? props.coverOpacity || 0.65 : 0)};
  }
`;

export default ModalManager;
