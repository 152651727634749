// @flow
import React from 'react';
import styled from 'styled-components';
import parse from 'html-react-parser';
import Button from '../common/Button';

const ConfirmModal = ({
  title,
  description,
  confirmLabel = 'Confirm',
  cancelLabel = 'Cancel',
  confirmIsDestructive = false,
  onConfirm,
  onDismiss,
}) => (
  <Wrapper>
    <Title>{title}</Title>
    <Body>{description ? parse(description) : ''}</Body>
    <Footer>
      <Button onClick={onDismiss} faded>
        {cancelLabel}
      </Button>
      <Button onClick={onConfirm} destructive={confirmIsDestructive}>
        {confirmLabel}
      </Button>
    </Footer>
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 2rem;

  @media only screen and (max-width: 768px) {
    margin: 20px;
    border-radius: 20px;
    padding-bottom: 1rem;
    text-align: center;
  }
`;

const Title = styled.div`
  font-size: 17px;
  font-weight: 700;
  color: ${(props) => props.theme.textColors.primary};
  letter-spacing: 0.2px;
  margin-bottom: 12px;
`;

const Body = styled.div`
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
  color: ${(props) => props.theme.textColors.primary};

  @media only screen and (max-width: 768px) {
    font-size: 15px;
    line-height: 1.7;
  }
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-top: 25px;
  width: 100%;

  button {
    margin: 0 5px;
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column-reverse;
    width: 100%;

    button {
      margin: 5px;
      width: 100%;
      font-size: 15px;
      height: 44px;
      border-radius: 44px;

      &:first-child {
        background-color: transparent;
      }
    }
  }
`;

export default ConfirmModal;
