import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from 'react-router-dom';
import HomePage from '../pages/HomePage';
import ErrorPage from '../pages/ErrorPage';
import LoginPage from '../pages/LoginPage';
import LogoutPage from '../pages/LogoutPage';
import UpgradePage from '../pages/UpgradePage';
import { openCreator } from '../api/creator';

const RootSwitch = ({ isAuthenticated }) => (
  <Router>
    <Switch>
      <Route exact path="/logout" render={() => <LogoutPage />} />

      <LoginRoute exact path="/login" isAuthenticated={isAuthenticated}>
        <LoginPage />
      </LoginRoute>

      <LoginRoute exact path="/creator" isAuthenticated={isAuthenticated}>
        <LoginPage />
      </LoginRoute>

      <PrivateRoute exact path="/" isAuthenticated={isAuthenticated}>
        <HomePage />
      </PrivateRoute>

      <PrivateRoute exact path="/upgrade" isAuthenticated={isAuthenticated}>
        <UpgradePage />
      </PrivateRoute>

      <PrivateRoute path="/oops" isAuthenticated={isAuthenticated}>
        <Page title="Something went wrong">
          <ErrorPage />
        </Page>
      </PrivateRoute>

      <Route
        render={({ location }) => (
          <Redirect to={{ pathname: '/oops', state: { from: location } }} />
        )}
      />
    </Switch>
  </Router>
);

const PrivateRoute = ({ path, exact, isAuthenticated, children }) => (
  <Route
    path={path}
    exact={exact}
    render={({ location }) =>
      isAuthenticated ? (
        children
      ) : (
        <Redirect to={{ pathname: '/login', state: { from: location } }} />
      )
    }
  />
);

const LoginRoute = ({ isAuthenticated, children }) => {
  // check if routing came from ?ref=creator
  const urlParams = new URLSearchParams(window.location.search);
  const ref = urlParams.get('ref');

  if (isAuthenticated && ref === 'creator') {
    // if so, user is only here to get a creator API key
    openCreator();
    return null;
  }

  return (
    <Route
      render={({ location }) =>
        isAuthenticated ? (
          <Redirect
            to={{
              pathname: location?.state?.from || '/',
              state: { from: location },
            }}
          />
        ) : (
          children
        )
      }
    />
  );
};

const Page = ({ title, children }) => {
  useEffect(() => {
    if (title) document.title = title;
  }, [title]);
  return children;
};

export default RootSwitch;
