import React, { useEffect, useContext } from 'react';
import DashLayout from '../layouts/DashLayout';
import Upgrade from '../components/upgrade/Upgrade';
import { SystemContext } from '../contexts/SystemContext';

const UpgradePage = () => {
  const {
    state: { isPro },
  } = useContext(SystemContext);

  useEffect(() => {
    // only free users should see the upgrade page
    if (isPro) window.location = '/';
  }, []);

  return (
    <DashLayout isPro={isPro}>
      <Upgrade />
    </DashLayout>
  );
};

export default UpgradePage;
