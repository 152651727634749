import { useRef, useEffect, useContext } from 'react';
import * as Realm from 'realm-web';
import { APP_ID, ENV } from '../constants';
import { SystemContext } from './contexts/SystemContext';

const realm = new Realm.App({
  id: APP_ID,
});

const Session = ({ children }) => {
  const isInitialMount = useRef(true);

  const { dispatch: dispatchSystem } = useContext(SystemContext);

  useEffect(() => {
    const onInit = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const key = urlParams.get('key');

      // Authentication key check // log in that user
      if (key) {
        try {
          // Authenticate the user
          const credentials = Realm.Credentials.apiKey(key);
          await realm.logIn(credentials);
          await realm.currentUser.refreshCustomData();
        } catch (err) {
          console.log('Error using key for login: ', err);
        }
      }

      // Subscription Checks
      if (realm.currentUser) {
        const stripeSuccess = urlParams.get('cb_stripe_success');

        // ------- NEW PURCHASE CHECK ------
        // new stripe transaction detected, upgrade user
        if (stripeSuccess) {
          const sessionId = urlParams.get('session_id');

          if (sessionId) {
            try {
              await realm.currentUser.functions.stripeCheckoutComplete(
                sessionId,
                ENV,
              );
              window.history.replaceState({}, document.title, '/');

              await realm.currentUser.refreshCustomData();

              dispatchSystem({
                type: 'SET_SYSTEM',
                data: {
                  realmUser: realm.currentUser,
                  isPro: realm.currentUser.customData?.subscription?.isValid,
                  loadUI: true,
                },
              });
            } catch (err) {
              console.log('error confirming stripe purchase: ', err);
            }
            return;
          }
        }

        // ------ VALIDATE EXISTING SUBSCRIPTION ----
        // check if existing subscription is still valid
        await realm.currentUser.refreshCustomData();
        const subscriptionValid =
          realm.currentUser?.customData?.subscription?.isValid;

        const subscription = realm?.currentUser?.customData?.subscription;
        const provider = subscription?.provider;

        if (subscriptionValid) {
          try {
            if (!provider || provider === 'apple') {
              // update our server with latest user subscription
              const { isValid } =
                await realm.currentUser.functions.checkAppleSubscription();
              console.log('apple sub isValid: ', isValid);
            } else if (provider === 'stripe') {
              const { isValid } =
                await realm.currentUser.functions.checkStripeSubscription(ENV);
              console.log('stripe sub isValid: ', isValid);
            }
            await realm.currentUser.refreshCustomData();
          } catch (err) {
            console.log(`Error on ${provider}: `, err);
          }
        }
      }

      dispatchSystem({
        type: 'SET_SYSTEM',
        data: {
          realmUser: realm.currentUser,
          isPro: realm.currentUser?.customData?.subscription?.isValid,
          loadUI: true,
        },
      });
    };

    if (isInitialMount.current) {
      isInitialMount.current = false;
      onInit();
    }
  }, [dispatchSystem]);

  return children;
};

export default Session;
