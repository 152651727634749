import React, { useRef, useMemo, memo, useContext } from 'react';
import styled from 'styled-components';
import { BsThreeDots, BsPlayFill } from 'react-icons/bs';
import { formatDistanceStrict } from 'date-fns';
import * as Realm from 'realm-web';
import useClipActions from '../../hooks/useClipActions';

import ContextMenu from '../common/ContextMenu';
import VideoInline from '../common/VideoInline';
import { ClipsContext } from '../../contexts/ClipsContext';

import { APP_ID } from '../../../constants';
import replayIcon from '../../../assets/images/icon-replay.svg';

const realm = Realm.App.getApp(APP_ID);

const GridClip = ({ config, isPlaying, onStartPlaying, onClipClick }) => {
  const contextRef = useRef(null);
  const vidRef = useRef(null);

  const {
    downloadClip,
    copyLink,
    editClip,
    deleteClip,
    addToPlaylist,
    removeFromPlaylist,
  } = useClipActions(config);

  const {
    state: { type: listType, playlist },
    dispatch: dispatchClips,
  } = useContext(ClipsContext);

  const MENU_OPTIONS = useMemo(() => {
    const manageClip = ['Edit Clip', 'Copy Link', 'Download'];
    const playlist = ['Add to Playlist'];
    if (listType === 'playlist') playlist.push('Remove from Playlist');

    return [
      ...manageClip,
      'separator',
      ...playlist,
      'separator',
      'Delete Clip',
    ];
  }, [listType]);

  // ------ DRAGGING ------

  const onDragStart = (e) => e.dataTransfer.setData('text', config._id);

  // ------ VIDEO PLAYBACK -------

  const togglePlayback = () => {
    if (vidRef.current.paused) vidRef.current.play();
    else vidRef.current.pause();
  };

  const onPlay = () => {
    if (isPlaying) {
      togglePlayback();
      return;
    }
    onStartPlaying(config?._id);
  };

  const onReplay = (e) => {
    e.stopPropagation();
    vidRef.current.currentTime = 0;
    vidRef.current.play();
  };

  const onVideoLoad = () => {
    // autoplay video
    vidRef.current.play();
  };

  const onVideoEnded = () => {
    onStartPlaying(null);
  };

  // ----- BUTTON HANDLERS -------

  const onClipTitleClick = () => {
    onClipClick(config);
  };

  const onTagClick = async (obj) => {
    const clipResults = await realm.currentUser.functions.searchClips(obj.tag);
    dispatchClips({
      type: 'SET_LIST',
      data: {
        title: obj.tagLabel,
        count: clipResults.length,
        list: clipResults,
        type: 'search',
      },
    });
  };

  // -------- CONTEXT MENU -----

  const onHideContext = () => {
    contextRef.current.onHide();
  };

  const onContextMenuSelect = (label) => {
    if (label === 'Edit Clip') editClip();
    else if (label === 'Copy Link') copyLink();
    else if (label === 'Download') downloadClip();
    else if (label === 'Delete Clip') deleteClip();
    else if (label === 'Add to Playlist') addToPlaylist();
    else if (label === 'Remove from Playlist') removeFromPlaylist(playlist);
  };

  return (
    <Wrapper>
      <Container
        draggable="true"
        onDragStart={onDragStart}
        onMouseLeave={onHideContext}>
        <MenuBtn>
          <ContextMenu
            ref={contextRef}
            options={MENU_OPTIONS}
            onSelect={onContextMenuSelect}
            position={{
              top: '24px',
              left: 'auto',
              right: '-22px',
              width: '154px',
            }}
            hasDelete>
            <BsThreeDots />
          </ContextMenu>
        </MenuBtn>

        <Content>
          <Thumbnail bgImage={config?.preview} blurred={isPlaying} />
          <Overlay />
          <Duration>{Math.ceil(config?.duration)} SEC</Duration>
          <PlayBtn onClick={onPlay}>
            <BsPlayFill />
          </PlayBtn>
          {isPlaying ? (
            <VideoWrapper>
              <VideoInline
                ref={vidRef}
                source={config?.clip}
                onLoad={onVideoLoad}
                onEnd={onVideoEnded}
              />
              <ReplayBtn onClick={onReplay} />
            </VideoWrapper>
          ) : null}
        </Content>
        <Info>
          <TitleBtn onClick={onClipTitleClick}>{config?.title}</TitleBtn>
          <Metadata>
            <Created>
              {formatDistanceStrict(config?.created, new Date(), {
                addSuffix: true,
              })}
            </Created>
            <TagsWrapper>
              {config?.tags.map((obj) => (
                <TagBtn
                  key={obj.tag}
                  onClick={() => onTagClick(obj)}>{`#${obj.tag}`}</TagBtn>
              ))}
            </TagsWrapper>
          </Metadata>
        </Info>
      </Container>
    </Wrapper>
  );
};

const MenuBtn = styled.div`
  position: absolute;
  z-index: 3;
  top: 0.5rem;
  right: 0.75rem;
  display: none;

  svg {
    color: white;
    font-size: 20px;
  }
`;

const Overlay = styled.div`
  position: absolute;
  display: none;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  background: radial-gradient(
    ellipse at center,
    rgba(0, 0, 0, 0.25) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );
`;

const PlayBtn = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  opacity: 0.85;
  cursor: pointer;

  svg {
    color: white;
    font-size: 72px;
  }
`;

const Created = styled.div`
  font-size: 14px;
  color: ${(props) => props.theme.textColors.secondary};
`;

const TagsWrapper = styled.div`
  display: none;
  height: auto;
  max-height: 50px;
  overflow: hidden;
  margin-left: -7px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

const Wrapper = styled.div`
  @media only screen and (max-width: 768px) {
    margin: 0.25rem;
  }
`;

const Container = styled.div`
  position: relative;
  display: block;
  opacity: 1;
  border-radius: 2px;
  width: 100%;
  height: 100%;
  max-width: 390px;
  background-color: white;
  box-shadow: 0 1px 4px rgba(85, 116, 162, 0.2);
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: rgba(255, 255, 255, 0.6);
    box-shadow: 0 4px 8px rgba(85, 116, 162, 0.2);

    ${MenuBtn} {
      display: block;
    }

    ${Overlay} {
      display: block;
    }

    ${PlayBtn} {
      display: flex;
    }

    ${TagsWrapper} {
      display: -webkit-box;
    }

    ${Created} {
      display: none;
    }
  }

  .dragging {
    opacity: 0.5;
  }
`;

const Content = styled.div`
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 2px 2px 0 0;
  padding-bottom: 56.25%;
  background-color: #444;
`;

const Duration = styled.div`
  position: absolute;
  bottom: 0.5rem;
  left: 0.5rem;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 4px;
  font-size: 11px;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 0.5px;
  color: white;
  text-transform: uppercase;
  pointer-events: none;
  user-select: none;

  @media only screen and (max-width: 768px) {
    left: auto;
    right: 0.5rem;
  }
`;

const Thumbnail = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
  background-image: url(${(props) => props.bgImage});

  ${({ blurred }) =>
    blurred &&
    `
    filter: blur(20px);
  `}
`;

const VideoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
`;

const ReplayBtn = styled.div`
  position: absolute;
  top: 1rem;
  left: 1rem;
  width: 18px;
  height: 18px;
  background-color: transparent;
  background-image: url(${replayIcon});
  background-position: 50%;
  background-size: 100%;
  background-repeat: no-repeat;
  cursor: pointer;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  width: 100%;
  padding: 0.5rem 0.75rem;

  @media only screen and (max-width: 768px) {
    padding: 0.25rem 0.5rem;
  }
`;

const TitleBtn = styled.div`
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 0.25rem;
  overflow: hidden;
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  color: ${(props) => props.theme.textColors.primary};

  &:hover {
    color: ${(props) => props.theme.colors.primary};
  }

  @media only screen and (max-width: 768px) {
    font-size: 14px;
    margin-bottom: 0;
  }
`;

const Metadata = styled.div`
  font-size: 13px;
  color: ${(props) => props.theme.textColors.secondary};
  line-height: 28px;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const TagBtn = styled.span`
  display: inline-block;
  margin-left: 7px;
  max-width: 100%;
  font-size: 13px;
  color: ${(props) => props.theme.textColors.secondary};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  line-height: 1.1;
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.colors.primary};
  }
`;

export default memo(GridClip);
