// @flow
import React, { useContext } from 'react';
import styled from 'styled-components';
import { MdEdit, MdDownload, MdOutlineLink, MdPlayArrow } from 'react-icons/md';
import { FaPaperPlane, FaRegTrashAlt } from 'react-icons/fa';
import { ClipsContext } from '../../contexts/ClipsContext';
import useClipActions from '../../hooks/useClipActions';

const ACTIONS = [
  {
    id: 'download',
    icon: <MdDownload />,
    iconSize: '23px',
    label: 'Save to Camera Roll',
    restricted: true,
  },
  {
    id: 'share',
    icon: <FaPaperPlane />,
    iconSize: '15px',
    label: 'Share',
  },
  {
    id: 'copyLink',
    icon: <MdOutlineLink />,
    iconSize: '21px',
    label: 'Copy Link',
  },
  {
    id: 'preview',
    icon: <MdPlayArrow />,
    iconSize: '21px',
    label: 'Preview',
  },
  { id: 'divider' },
  {
    id: 'edit',
    icon: <MdEdit />,
    iconSize: '19px',
    label: 'Edit',
  },
  {
    id: 'delete',
    icon: <FaRegTrashAlt />,
    iconSize: '16px',
    label: 'Delete',
  },
];

const ActionSheetModal = ({ config, onDismiss }) => {
  const { copyLink, deleteClipPrompt, downloadClip, editClip, shareClip } =
    useClipActions(config);

  const { dispatch: dispatchClips } = useContext(ClipsContext);

  const onAction = (id) => {
    if (id === 'download') {
      downloadClip();
      onDismiss();
    } else if (id === 'share') {
      shareClip();
    } else if (id === 'preview') {
      dispatchClips({ type: 'SET_PREVIEW', data: config });
      onDismiss();
    } else if (id === 'copyLink') {
      copyLink();
    } else if (id === 'edit') {
      editClip();
    } else if (id === 'delete') {
      deleteClipPrompt();
    }
  };

  return (
    <Container>
      <Wrapper>
        <Header>
          <Thumb src={config.preview} />
          <Title>{config.title}</Title>
        </Header>
        <Content>
          {ACTIONS.map((item) =>
            item.id === 'divider' ? (
              <Divider key={item.id} />
            ) : (
              <ActionItem
                key={item.id}
                onClick={() => onAction(item.id)}
                destructive={item.id === 'delete'}
                isShare={item.id === 'share'}
                iconSize={item.iconSize}>
                <span>{item.icon}</span>
                <label>{item.label}</label>
                {item.restricted ? <ProBadge>PRO</ProBadge> : null}
              </ActionItem>
            ),
          )}
        </Content>
      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const ActionItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem 0.35rem;

  label {
    flex: 1;
    text-transform: none;
    color: ${(props) => props.theme.textColors.primary};
    letter-spacing: 0.2px;
    font-size: 15px;
    line-height: 1;
    margin-left: 5px;
    margin-right: 15px;
    margin-bottom: 0;
  }

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
  }

  svg {
    color: ${(props) => props.theme.colors.primary};
    margin-right: 1rem;
    font-size: ${(props) => props.iconSize || '20px'};
  }

  ${({ destructive, theme }) =>
    destructive &&
    `
		label, svg {
			color: ${theme.colors.warning};
		}
	`}

  &:active {
    background-color: ${(props) => props.theme.backgroundColors.lightAlt};
  }
`;

const Wrapper = styled.div`
  position: relative;
  z-index: 2;
  background-color: white;
  border-radius: 15px 15px 0 0;
  max-height: 500px;

  display: flex;
  flex-direction: column;
  pointer-events: auto;
  padding-top: 0.25rem;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  margin: 0 1rem;
  padding: 1rem 5px;
  border-bottom: 1px solid ${(props) => props.theme.colors.border};
`;

const Thumb = styled.img`
  display: block;
  width: 44px;
  height: 44px;
  object-fit: cover;
  border-radius: 2px;
  overflow: hidden;
  margin-right: 20px;
`;

const Title = styled.div`
  flex: 1;
  font-size: 16px;
  line-height: 1;
  color: ${(props) => props.theme.textColors.primary};
  font-weight: ${(props) => props.theme.fontWeights.bold};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const Content = styled.div`
  flex: 1;
  overflow-y: scroll;
  padding: 0.5rem 1rem 1rem;
`;

const ProBadge = styled.div`
  display: block;
  padding: 4px 8px;
  border: 2px solid ${(props) => props.theme.colors.secondary};
  color: ${(props) => props.theme.colors.secondary};
  font-family: ${(props) => props.theme.fonts.primary};
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 1;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 40px;
  background: transparent;
  margin-left: auto;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin: 5px 0;
  background-color: ${(props) => props.theme.colors.border};
`;

export default ActionSheetModal;
