import React, { forwardRef, memo } from 'react';
import styled from 'styled-components';

const VideoInline = forwardRef(({ source, onEnd, onLoad }, ref) => {
  const togglePlayback = () => {
    if (ref.current.paused) ref.current.play();
    else ref.current.pause();
  };

  return (
    <Wrapper>
      <video
        ref={ref}
        onClick={togglePlayback}
        onLoadedMetadata={onLoad}
        onEnded={onEnd}>
        <source src={source} />
      </video>
    </Wrapper>
  );
});

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;

  video {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export default memo(VideoInline);
