import React from 'react';
import styled from 'styled-components';
import parse from 'html-react-parser';
import { lighten } from 'polished';

const ErrorMessage = ({ title, description }) => (
  <ErrorWrapper>
    <ErrorTitle>{title}</ErrorTitle>
    <ErrorDescription>{parse(description)}</ErrorDescription>
    <ErrorButton onClick={() => (window.location.href = '/')}>
      Return to home screen
    </ErrorButton>
  </ErrorWrapper>
);

const ErrorWrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  margin: 0 auto;
  max-width: 840px;
  padding: 80px;
`;

const ErrorTitle = styled.h1`
  font-weight: ${props => props.theme.fontWeights.medium};
`;

const ErrorDescription = styled.p`
  color: ${props => props.theme.textColors.secondary};
  line-height: 1.8;
  margin-bottom: 40px;
`;

const ErrorButton = styled.button`
  display: block;
  border: none;
  border-radius: 8px;
  padding: 12px 24px;
  color: white;
  font-family: ${props => props.theme.fonts.secondary};
  font-weight: ${props => props.theme.fontWeights.medium};
  background-color: ${props => props.theme.colors.primary};
  outline: none;

  &:hover {
    background-color: ${props => lighten(0.03, props.theme.colors.primary)};
    cursor: pointer;
  }
`;

export default ErrorMessage;
