import React, { useCallback, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { googleLogout } from '@react-oauth/google';
import * as Realm from 'realm-web';
import styled from 'styled-components';
import { APP_ID } from '../../constants';

const realm = Realm.App.getApp(APP_ID);

const LogoutPage = () => {
  const isInitialMount = useRef(true);

  const history = useHistory();

  const logout = useCallback(async () => {
    try {
      googleLogout();
    } catch (err) {
      // catch err
    }

    try {
      await realm.currentUser.logOut();
    } catch (error) {
      console.log('issue with realm logout: ', error);
    }

    window.location = '/login';
  }, [history]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      logout();
    }
  }, [logout]);

  return <Container />;
};

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: white;
`;

export default LogoutPage;
