import { useEffect, useCallback, useRef } from 'react';

const useOutsideClick = (onClick) => {
  const ref = useRef(null);

  const handleClick = useCallback(
    (e) => {
      if (!ref.current) return;

      const inside = ref.current.contains(e.target);
      if (inside) return;

      onClick();
    },
    [onClick, ref],
  );

  useEffect(() => {
    if (ref.current) {
      document.addEventListener('click', handleClick);
    }

    return () => document.removeEventListener('click', handleClick);
  }, [handleClick]);

  return ref;
};

export default useOutsideClick;
