import React, { useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import DashHeader from '../components/common/DashHeader';
import DashMobileUI from '../components/common/DashMobileUI';
import AlertCenter from '../components/common/AlertCenter';
import ModalManager from '../components/modals/ModalManager';
import { IS_MOBILE } from '../../constants';

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    box-sizing: border-box;
    padding: 0;
  }
  body, html {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: ${(props) => props.theme.fonts.secondary};
    font-size: 18px;
    font-weight: ${(props) => props.theme.fontWeights.regular};
    line-height: 1.8;
    color: ${(props) => props.theme.textColors.primary};
    background-color: ${(props) => props.theme.backgroundColors.secondary};
    -webkit-font-smoothing: antialiased;
  }
`;

const DashLayout = ({ isPro, children }) => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const onToggleMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  return (
    <Container mobileMenu={showMobileMenu}>
      <GlobalStyle />
      {IS_MOBILE ? (
        <DashMobileUI isPro={isPro}>{children}</DashMobileUI>
      ) : (
        <>
          <DashHeader isPro={isPro} onMobileMenu={onToggleMobileMenu} />
          <Content>{children}</Content>
        </>
      )}

      <ModalManager />
      <AlertCenter />
    </Container>
  );
};

const Container = styled.div`
  transition: all 0.4s ease-in-out;
  width: 100%;
  background-color: ${(props) => props.theme.backgroundColors.primary};

  ${({ mobileMenu }) =>
    mobileMenu &&
    `
		transform: translateX(-200px);
	`}
`;

const Content = styled.div`
  position: relative;
  padding-top: 72px;
  height: 100%;
`;

export default DashLayout;
