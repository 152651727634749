import React, { memo } from 'react';
import styled from 'styled-components';
import { MdAddCircle } from 'react-icons/md';
import StarButton from '../common/StarButton';
import usePlaylistActions from '../../hooks/usePlaylistActions';

import publicIcon from '../../../assets/images/icon-public.svg';

const SidebarItem = ({
  id,
  title,
  home,
  selected,
  isPublic,
  starred,
  create,
  onClick,
  onPlaylistCreate,
}) => {
  const { starPlaylist } = usePlaylistActions();

  const onStar = (e) => {
    e.stopPropagation();
    starPlaylist(id, !starred);
  };

  return (
    <Btn
      selected={selected}
      title={title}
      onClick={() =>
        create ? onPlaylistCreate() : onClick(id, home ? true : false)
      }>
      <Content selected={selected} create={create}>
        {create ? (
          <AddIcon>
            <MdAddCircle />
          </AddIcon>
        ) : null}
        <Title>{title}</Title>
        {isPublic ? <PublicIcon /> : null}
        {create || home ? null : (
          <StarButtonWrapper>
            <StarButton starred={starred} size="small" onClick={onStar} />
          </StarButtonWrapper>
        )}
      </Content>
    </Btn>
  );
};

const Title = styled.div`
  font-size: 16px;
  color: ${(props) => props.theme.textColors.primary};
  line-height: 28px;
  align-items: center;
  overflow: hidden;
  transition: color 0.2s ease-in-out;
  text-overflow: ellipsis;
  text-transform: capitalize;
  padding-top: 2px;
`;

const AddIcon = styled.div`
  display: flex;
  justify-content: center;
  font-size: 18px;
  color: ${(props) => props.theme.colors.icon};
  margin-right: 7px;
`;

const PublicIcon = styled.div`
  display: block;
  width: 23px;
  height: 16px;
  background: url(${publicIcon}) no-repeat;
  background-size: 16px;
  background-position: center right;
`;

const Content = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  transition: background-color 0.2s ease-in-out;
  max-width: 200px;
  width: 100%;
  padding: 0.5rem 2rem;
  background-color: transparent;
  white-space: nowrap;

  ${({ create }) =>
    create &&
    `
    padding: 1rem 2rem;
  `}

  ${({ selected, theme }) =>
    selected &&
    `
    ${Title} {
      font-weight: 700;
      color: ${theme.colors.secondary};
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      width: 8px;
      height: 32px;
      background-color: ${theme.colors.secondary};
    }
  `}
`;

const StarButtonWrapper = styled.div`
  display: none;
`;

const Btn = styled.div`
  position: relative;
  display: block;
  cursor: pointer;

  ${({ selected }) =>
    selected &&
    `
    cursor: default;
    pointer-events: none;
  `}

  &:hover {
    ${Content} {
      background-color: #ebecee;
    }

    ${StarButtonWrapper} {
      display: block;
    }

    ${PublicIcon} {
      display: none;
    }
  }
`;

export default memo(SidebarItem);
