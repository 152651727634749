// @flow
import React, { useMemo, useContext, useState } from 'react';
import styled from 'styled-components';
import { EJSON } from 'bson';
import ClipListItem from './ClipListItem';
import FreeAlert from './FreeAlert';
import ActionSheetModal from '../modals/ActionSheetModal';
import { ModalContext } from '../../contexts/ModalContext';
import { SystemContext } from '../../contexts/SystemContext';

import { FREE_CLIP_COUNT } from '../../../constants';

const FreeList = ({ data, onClipClick }) => {
  const {
    state: { realmUser },
  } = useContext(SystemContext);

  const customData = EJSON.deserialize(realmUser.customData);

  const { dispatch: dispatchModal } = useContext(ModalContext);

  const [showAlert, setShowAlert] = useState(true);

  const clipCount = customData?.clipCount || 0;
  const subscription = customData?.subscription;
  const wasPro = subscription && !subscription.isValid;
  const list = useMemo(() => data.slice(0, FREE_CLIP_COUNT), [data]);

  const onMenuClick = (e, config) => {
    e.stopPropagation();

    dispatchModal({
      type: 'ADD_MODAL',
      data: {
        component: ActionSheetModal,
        props: {
          config,
        },
      },
    });
  };

  return (
    <Wrapper>
      {showAlert ? (
        <FreeAlert
          endDate={wasPro ? subscription?.endDate : null}
          wasPro={wasPro}
          count={clipCount > FREE_CLIP_COUNT ? FREE_CLIP_COUNT : clipCount}
          limitExceeded={clipCount > FREE_CLIP_COUNT}
          onDismiss={() => setShowAlert(false)}
        />
      ) : null}

      <label>My Clips</label>
      {list.map((clip) => (
        <ClipListItem
          key={clip._id.toString()}
          config={clip}
          onClipClick={onClipClick}
          onMenuClick={onMenuClick}
        />
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  flex: 1;
  padding: 1rem 0.75rem 100px;
  overflow-y: scroll;

  label {
    color: ${(props) => props.theme.textColors.primary};
    font-size: 15px;
  }
`;

export default FreeList;
