import React, { memo } from 'react';
import styled from 'styled-components';
import { formatDistanceStrict } from 'date-fns';
import { MdMoreHoriz } from 'react-icons/md';

const ClipListItem = ({ config, onClipClick, onMenuClick }) => (
  <Container>
    <BtnWrapper onClick={() => onClipClick(config)}>
      <ThumbWrapper>
        <Thumbnail src={config?.preview} />
        <Duration>{Math.ceil(config?.duration)}S</Duration>
      </ThumbWrapper>
      <TextWrapper>
        <Title>{config?.title}</Title>
        <Created>
          {formatDistanceStrict(config?.created, new Date(), {
            addSuffix: true,
          })}
        </Created>
      </TextWrapper>
    </BtnWrapper>
    <MenuBtn onClick={(e) => onMenuClick(e, config)}>
      <MdMoreHoriz />
    </MenuBtn>
  </Container>
);

const BtnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  overflow: hidden;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  margin: 12px 0;
`;

const ThumbWrapper = styled.div`
  position: relative;
  display: block;
  background-color: ${(props) => props.theme.backgroundColors.lightAlt};
  margin-right: 15px;
`;

const Duration = styled.div`
  position: absolute;
  bottom: 5px;
  left: 5px;
  background-color: rgba(255, 255, 255, 0.55);
  padding: 2px 6px;
  border-radius: 30px;
  font-size: 11px;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 0.5px;
  color: rgba(0, 0, 0, 0.8);
  text-transform: uppercase;
  pointer-events: none;
  user-select: none;
`;

const Thumbnail = styled.img`
  display: block;
  width: 80px;
  height: 60px;
  object-fit: cover;
  overflow: hidden;
  border-radius: 5px;
`;

const TextWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  margin-right: 15px;
`;

const Title = styled.div`
  color: ${(props) => props.theme.textColors.primary};
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const Created = styled.div`
  font-size: 14px;
  line-height: 1;
  color: ${(props) => props.theme.textColors.secondary};
`;

const MenuBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: -2px;
  height: 28px;
  width: 28px;
  border-radius: 50%;
  border: 2px solid ${(props) => props.theme.colors.border};

  svg {
    color: ${(props) => props.theme.textColors.secondary};
    font-size: 17px;
  }
`;

export default memo(ClipListItem);
