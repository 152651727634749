import React from 'react';
import styled from 'styled-components';

const WithTooltip = ({ children, title, left, light, bottom }) => (
  <Wrapper>
    <Tooltip
      left={left}
      bottom={bottom}
      disabled={!title || title === ''}
      light={light}>
      {title}
    </Tooltip>
    {children}
  </Wrapper>
);

const Tooltip = styled.div`
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%) translateY(-100%);
  background-color: ${(props) =>
    props.light ? 'white' : props.theme.textColors.secondary};
  color: ${(props) => (props.light ? props.theme.colors.blue : 'white')};
  font-size: 12px;
  font-weight: ${(props) => props.theme.fontWeights.medium};
  line-height: 1;
  letter-spacing: 0.1px;
  padding: 10px 15px;
  border-radius: 6px;
  display: none;
  white-space: nowrap;
  opacity: ${(props) => (props.disabled ? 0 : 1)};
  pointer-events: none;

  ${({ left }) =>
    left &&
    `
    top: 50%;
    left: -5px;
    transform: translateX(-100%) translateY(-50%);
  `}

  ${({ bottom }) =>
    bottom &&
    `
    top: 100%;
    margin-top: 5px;
    transform: translateX(-50%);
  `}
`;

const Wrapper = styled.div`
  position: relative;

  &:hover ${Tooltip} {
    display: block;
  }
`;

export default WithTooltip;
