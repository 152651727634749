// @flow
import React, { useRef, useEffect, useContext } from 'react';
import styled, { keyframes } from 'styled-components';
import { FaTimes } from 'react-icons/fa';
import { ClipsContext } from '../../contexts/ClipsContext';
import { IS_MOBILE } from '../../../constants';

const Alert = ({ id, type, icon, message, removeAlert, showClose }) => {
  const isInitialMount = useRef(true);
  const Icon = icon || null;

  const {
    state: { preview },
  } = useContext(ClipsContext);

  // on component mount, animate in, wait 3s, animate out
  useEffect(() => {
    let timeout;

    if (showClose) return;

    if (isInitialMount.current) {
      isInitialMount.current = false;

      timeout = setTimeout(() => {
        removeAlert();
      }, 3000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [id, showClose]);

  return (
    <Container position={IS_MOBILE && !preview ? 'bottom' : 'top'}>
      <Wrapper type={type}>
        {icon ? <Icon color="white" /> : null}
        <Message>{message}</Message>
        {showClose ? (
          <CloseBtn onClick={removeAlert}>
            <FaTimes />
          </CloseBtn>
        ) : null}
      </Wrapper>
    </Container>
  );
};

const animInTop = keyframes`
  0% { 
    transform: translateY(-30px);
    opacity: 0;
  }
  100% { 
    transform: translateY(0);
    opacity: 1;
  }
`;

const animInBottom = keyframes`
  0% { 
    transform: translateY(30px);
    opacity: 0;
  }
  100% { 
    transform: translateY(0);
    opacity: 1;
  }
`;

const animCenter = keyframes`
  0% { opacity: 0; transform: scale(0) }
  100% { opacity: 1; transform: scale(2) }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: auto;
  padding: 30px 30px 20px;
  opacity: 0;
  transform: translateY(-30px);

  animation-duration: 0.3s;
  animation-timing-function: ease-out;
  animation-delay: 0.2s;
  animation-fill-mode: forwards;
  animation-name: ${(props) =>
    props.position === 'bottom' ? animInBottom : animInTop};

  ${({ position }) =>
    position === 'bottom' &&
    `
        padding: 10px 10px 20px;
        transform: translateY(30px);
        margin-bottom: initial;
    `}
`;

const Wrapper = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    props.type === 'toast'
      ? `rgba(${props.theme.textColors.primaryRGB}, 0.8)`
      : props.type === 'warn'
      ? props.theme.colors.warning
      : props.theme.colors.primary};
  border-radius: ${(props) => (props.type === 'toast' ? '100px' : '10px')};
  padding: 0.75rem 1.5rem;
  margin-bottom: 20px;

  > svg {
    margin-right: 5px;
  }

  @media only screen and (max-width: 768px) {
    padding: 0.75rem 1rem;

    > svg {
      margin-right: 10px;
      font-size: 22px;
    }
  }
`;

const Message = styled.div`
  padding: 5px;
  color: white;
  font-size: 15px;
  line-height: 1.3;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  flex: 1;
`;

const CloseBtn = styled.button`
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  line-height: 1;
  padding: 5px;
  margin-left: 5px;
  pointer-events: auto;

  svg {
    color: white;
    font-size: 16px;
    opacity: 0.7;
    margin-right: 0;
  }

  &:hover {
    svg {
      opacity: 1;
    }
  }

  @media only screen and (max-width: 768px) {
    svg {
      font-size: 24px;
    }
  }
`;

export default Alert;
