import React, { forwardRef, memo, useState, useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import { FiVolumeX, FiVolume2 } from 'react-icons/fi';

const ADDED_LOOPS_BEFORE_MUTE = 2;

const Video = forwardRef(({ source, onEnd, onLoad, onTimeUpdate }, ref) => {
  const isInitialMount = useRef(true);
  const loopCount = useRef(0);
  const [showMuteIndicator, setShowMuteIndicator] = useState(false);
  const [muted, setMuted] = useState(true);

  const toggleMute = () => {
    if (muted) {
      ref.current.currentTime = 0;
      loopCount.current = 0;
    }

    setShowMuteIndicator(true);
    setMuted(!muted);
  };

  const onVideoEnded = () => {
    loopCount.current = loopCount.current + 1;
    if (!muted && loopCount.current > ADDED_LOOPS_BEFORE_MUTE) toggleMute();

    ref.current.currentTime = 0;
    ref.current.play();

    if (onEnd) onEnd();
  };

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    // once animation is triggered, run timer so it resets state
    const resetAnim = setTimeout(() => {
      setShowMuteIndicator(false);
    }, 600);

    return () => clearTimeout(resetAnim);
  }, [muted]);

  return (
    <Wrapper>
      <video
        ref={ref}
        onTimeUpdate={onTimeUpdate}
        onClick={toggleMute}
        onLoadedMetadata={onLoad}
        onEnded={onVideoEnded}
        autoPlay
        muted={muted}
        playsInline>
        <source src={source} />
      </video>
      {showMuteIndicator ? (
        <MuteWrapper>
          <MuteIndicator>{muted ? <FiVolumeX /> : <FiVolume2 />}</MuteIndicator>
        </MuteWrapper>
      ) : null}
    </Wrapper>
  );
});

const muteChange = keyframes`
  0% { opacity: 0; transform: scale(0) }
  75% { opacity: 1; }
  100% { opacity: 0.4; transform: scale(2) }
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;

  video {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const MuteWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
`;

const MuteIndicator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transform: scale(0);

  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.2);

  animation-name: ${muteChange};
  animation-duration: 0.6s;
  animation-timing-function: ease-out;
  animation-fill-mode: none;

  svg {
    font-size: 36px;
    color: white;
  }
`;

export default memo(Video);
