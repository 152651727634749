// @flow
import React, { useContext } from 'react';
import styled from 'styled-components';
import { MdClose, MdEdit } from 'react-icons/md';
import usePlaylistActions from '../../hooks/usePlaylistActions';
import { ClipsContext } from '../../contexts/ClipsContext';

const PlaylistHeader = ({ playlist, listMode, onClose }) => {
  const { dispatch: dispatchClips } = useContext(ClipsContext);

  const { editPlaylist } = usePlaylistActions();

  const onClear = () => {
    dispatchClips({ type: 'CLEAR' });
  };

  return (
    <Wrapper listMode={listMode}>
      <CloseBtn onClick={listMode ? onClose : onClear}>
        <MdClose />
      </CloseBtn>
      <Title>{listMode ? 'My Playlists' : playlist?.title}</Title>
      {listMode ? null : (
        <>
          <Count>({playlist?.count})</Count>
          <EditBtn onClick={() => editPlaylist()}>
            <MdEdit />
          </EditBtn>
        </>
      )}
    </Wrapper>
  );
};

const EditBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;

  border: 1px solid ${(props) => props.theme.colors.border};
  margin-left: auto;

  svg {
    font-size: 18px;
    color: ${(props) => props.theme.textColors.secondary};
  }
`;

const CloseBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(
    ${(props) => props.theme.textColors.secondaryRGB},
    0.2
  );

  svg {
    font-size: 20px;
    color: ${(props) => props.theme.textColors.primary};
  }
`;

const Text = styled.div`
  font-size: 18px;
  color: ${(props) => props.theme.textColors.primary};
`;

const Title = styled(Text)`
  margin-left: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const Count = styled.div`
  padding: 0 6px;
  font-size: 0.9rem;
  color: ${(props) => props.theme.textColors.secondary};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  flex-wrap: nowrap;

  ${({ listMode, theme }) =>
    listMode &&
    `
    ${Title} {
      font-weight: ${theme.fontWeights.medium};
      margin-left: 3px;
    }

    ${CloseBtn} {
      order: 2;
      margin-left: auto;
    }
  `}
`;

export default PlaylistHeader;
