import React, { useRef, useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import * as Realm from 'realm-web';
import { Link } from 'react-router-dom';
import { RiVideoAddFill } from 'react-icons/ri';
import { MdPlaylistPlay } from 'react-icons/md';
import ClipPreview from '../home/ClipPreview';
import PlaylistHeader from '../home/PlaylistHeader';
import PlaylistSelector from '../home/PlaylistSelector';
import InstallPrompt from './InstallPrompt';
import SearchBar from './SearchBar';
import UserIcon from './UserIcon';
import AccountModal from '../modals/AccountModal';
import { ClipsContext } from '../../contexts/ClipsContext';
import { ModalContext } from '../../contexts/ModalContext';
import { SystemContext } from '../../contexts/SystemContext';
import { openCreator } from '../../api/creator';
import { AD_UNITS, IS_IOS, IS_STANDALONE } from '../../../constants';

import logo from '../../../assets/images/logo.svg';

// import GoogleAd from './GoogleAd';

const DashMobileUI = ({ isPro, children }) => {
  const deferredInstallPrompt = useRef();

  const {
    state: { preview, playlist },
  } = useContext(ClipsContext);
  const { dispatch: dispatchModal } = useContext(ModalContext);
  const {
    state: { realmUser },
  } = useContext(SystemContext);

  const [showInstallBanner, setShowInstallBanner] = useState(false);
  const [showPlaylists, setShowPlaylists] = useState(false);
  const [hidePlaylistBtn, setHidePlaylistBtn] = useState(false);

  const onAccountClick = () => {
    dispatchModal({
      type: 'ADD_MODAL',
      data: {
        component: AccountModal,
        props: {
          maxWidth: '600px',
          borderRadius: '12px',
        },
      },
    });
  };

  const onInstallToHomeScreen = async () => {
    setShowInstallBanner(false);

    deferredInstallPrompt.current.prompt();
    const { outcome } = await deferredInstallPrompt.current.userChoice;
    console.log(`User response to the install prompt: ${outcome}`);
    deferredInstallPrompt.current = null;
  };

  useEffect(() => {
    const showAddToHomeScreenPrompt = (e) => {
      e.preventDefault();
      deferredInstallPrompt.current = e;
      setShowInstallBanner(true);
    };

    // Checks if should display install popup notification:
    if (!IS_IOS && !IS_STANDALONE) {
      window.addEventListener('beforeinstallprompt', showAddToHomeScreenPrompt);
    }

    return () => {
      try {
        window.removeEventListener(
          'beforeinstallprompt',
          showAddToHomeScreenPrompt,
        );
      } catch (err) {}
    };
  }, []);

  return (
    <Container>
      {showInstallBanner ? (
        <InstallPrompt
          isPro={isPro}
          onInstall={onInstallToHomeScreen}
          onDismiss={() => setShowInstallBanner(false)}
        />
      ) : null}
      <Header>
        <HeaderWrapperNav>
          <LogoWrapper>
            <Link to="/">
              <Logo>Clipbox</Logo>
            </Link>
          </LogoWrapper>

          <NavBar>
            <NavItem create>
              <a onClick={openCreator}>
                <RiVideoAddFill />
              </a>
            </NavItem>

            <NavItem avatar>
              <a onClick={onAccountClick}>
                <UserIcon user={realmUser} size="38px" />
              </a>
            </NavItem>
          </NavBar>
        </HeaderWrapperNav>
      </Header>
      {isPro && window.location.pathname === '/' ? (
        <SearchWrapper>
          {playlist || showPlaylists ? (
            <PlaylistHeader
              playlist={playlist}
              listMode={showPlaylists}
              onClose={() => setShowPlaylists(false)}
            />
          ) : (
            <>
              <SearchBar
                onFocusChange={(mode) => setHidePlaylistBtn(mode)}
                mobile
              />
              {hidePlaylistBtn ? null : (
                <PlaylistBtn onClick={() => setShowPlaylists(true)}>
                  <MdPlaylistPlay />
                </PlaylistBtn>
              )}
            </>
          )}
        </SearchWrapper>
      ) : null}

      <Content>
        {children}
        <PlaylistSelector
          isVisible={showPlaylists}
          onClose={() => setShowPlaylists(false)}
        />
      </Content>

      {preview ? (
        <PreviewWrapper>
          <ClipPreview config={preview} />
        </PreviewWrapper>
      ) : null}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Header = styled.header`
  width: 100%;
`;

const HeaderWrapperNav = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: white;
  border-bottom: 2px solid ${(props) => props.theme.colors.border};
  padding: 20px 20px 20px 0;
  line-height: 2rem;
  font-size: 0.875rem;
  width: 100%;
`;

const LogoWrapper = styled.div`
  display: flex;
  min-width: 200px;
  padding-left: 2rem;
  margin-right: 2rem;

  a {
    display: flex;
    justify-content: center;
  }

  @media only screen and (max-width: 922px) {
    min-width: auto;
  }

  @media only screen and (max-width: 768px) {
    padding-left: 1rem;
    margin-right: 1rem;
  }
`;

const Logo = styled.div`
  min-width: 120px;
  background: url(${logo}) no-repeat 50%;
  background-size: contain;
  font-size: 0;
`;

const NavBar = styled.ul`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
`;

const NavItem = styled.li`
  position: relative;

  a {
    display: flex;
    justify-content: center;
    font-size: 16px;
    color: ${(props) =>
      props.signout
        ? props.theme.colors.warning
        : props.theme.textColors.primary};
    font-weight: ${(props) =>
      props.active
        ? props.theme.fontWeights.bold
        : props.theme.fontWeights.regular};

    &:hover {
      opacity: 0.8;
    }
  }

  ${({ search, theme }) =>
    search &&
    `
		svg {
			font-size: 21px;
			color: ${theme.textColors.primary};
		}
		a {
			opacity: 0.8;

			&:hover {
				opacity: 1 !important;
			}
		}
	`}

  ${({ create, theme }) =>
    create &&
    `
		margin-right: 1.75rem !important;

		svg {
			font-size: 28px;
			color: ${theme.textColors.primary};
			transform: scaleX(1.1);
			
		}
		a {
			opacity: 0.8;

			&:hover {
				opacity: 1 !important;
			}
		}
	`}

  ${({ avatar }) =>
    avatar &&
    `
		margin-right: 0 !important;
		min-width: 2.5rem;

		a {
			padding: 0;
		}
	`}

	@media only screen and (max-width: 992px) {
    margin-right: 2rem;
  }
`;

const SearchWrapper = styled.div`
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 15px 15px;

  border-bottom: 1px solid ${(props) => props.theme.colors.border};
`;

const Content = styled.div`
  position: relative;
  flex: 1;
  overflow: hidden;
`;

const PreviewWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
`;

const PlaylistBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 6px;
  margin-left: 12px;
  svg {
    font-size: 30px;
    color: ${(props) => props.theme.textColors.secondary};
    opacity: 0.8;
  }

  &:active {
    svg {
      color: ${(props) => props.theme.colors.primary};
      opacity: 1;
    }
  }
`;

export default DashMobileUI;
