import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import * as Realm from 'realm-web';
import jwt_decode from 'jwt-decode';
import AppleLogin from 'react-apple-login';
import { FaApple } from 'react-icons/fa';
import { GoogleLogin } from '@react-oauth/google';

import { openCreator } from '../api/creator';
import useAmplitude from '../hooks/useAmplitude';

import ActivityIndicator from '../components/common/ActivityIndicator';
import { APP_ID } from '../../constants';

import logo from '../../assets/images/logo.svg';

const realm = Realm.App.getApp(APP_ID);

const LoginPage = () => {
  const { trackLogin, trackRegisteredUser, setUserProperty } = useAmplitude();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const onLoginComplete = async (authType) => {
    const { subscription, loginCount } = realm.currentUser?.customData || {};

    // log in Amplitude
    const userProperties = {
      id: realm.currentUser.id,
      email:
        realm.currentUser.profile.email || realm.currentUser.customData.email,
      firstName: realm.currentUser.customData.first_name,
      lastName: realm.currentUser.customData.last_name,
      isPro: subscription.isValid ? 'Yes' : 'No',
    };
    if (loginCount && loginCount > 1) {
      trackLogin(authType, userProperties);
    } else {
      trackRegisteredUser(authType, userProperties);
    }

    // get clip count and update the record in Amplitude
    let clipCount = realm.currentUser.customData.clipCount;
    if (!clipCount) {
      clipCount = await realm.currentUser.functions.getClipCount();
      await realm.currentUser.refreshCustomData(); // not sure if we need this or not since we already have the count
    }
    setUserProperty({ 'Clips Created': clipCount });

    // check if routed from Creator app (ref=creator) => take back to Creator
    const urlParams = new URLSearchParams(window.location.search);
    const ref = urlParams.get('ref');

    if (ref === 'creator') openCreator();
    else {
      window.location = '/';
    }
  };

  const onGoogleLogin = async (credentialResponse) => {
    const credential = Realm.Credentials.google({
      idToken: credentialResponse.credential,
    });
    const user = await realm.logIn(credential);
    // if user doesn't have email saved in customData
    if (!user.customData.email) {
      const jwtData = jwt_decode(credentialResponse.credential);
      const userData = {
        email: jwtData.email,
        firstName: jwtData.given_name,
        lastName: jwtData.family_name,
        photo: jwtData.picture,
      };
      await user.functions.saveUserData(userData);
      realm.currentUser.refreshCustomData();
    }
    // complete login
    onLoginComplete('Google');
  };

  const onAppleLogin = async (response) => {
    const credential = Realm.Credentials.jwt(response.authorization.id_token);
    await realm.logIn(credential);
    onLoginComplete('Apple');
  };

  return (
    <Container>
      <Header>
        <a href="https://clipbox.app">
          <Logo src={logo} />
        </a>
      </Header>
      <Content>
        <Box>
          <h1>Log in or Sign up</h1>
          {loading ? (
            <ButtonsWrapper>
              <ActivityIndicator size={24} color="secondary" />
            </ButtonsWrapper>
          ) : (
            <ButtonsWrapper>
              <GoogleLogin
                onSuccess={onGoogleLogin}
                onError={() => {
                  console.log('Login Failed');
                }}
                width="300"
                logo_alignment="center"
                theme="outline"
              />
              <AppleLogin
                clientId="app.clipbox.web"
                redirectURI="https://web.clipbox.app"
                usePopup={true}
                callback={onAppleLogin} // Catch the response
                scope="email name"
                responseMode="query"
                render={(
                  renderProps, //Custom Apple Sign in Button
                ) => (
                  <button
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}>
                    <FaApple />
                    Continue with Apple
                  </button>
                )}
              />
            </ButtonsWrapper>
          )}

          {error ? <Error>{error}</Error> : null}

          <Legal>
            <p>
              By clicking "Continue with Apple/Google" above, you acknowledge
              that you have read and understood, and agree to Clipbox's{' '}
              <a href="https://clipbox.app/terms" target="_blank">
                Terms & Conditions
              </a>{' '}
              and{' '}
              <a href="https://clipbox.app/privacy" target="_blank">
                Privacy Policy
              </a>
              .
            </p>
          </Legal>
        </Box>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: ${(props) => props.theme.backgroundColors.primary};
  max-width: 1200px;
  margin: 0 auto;

  @media only screen and (max-width: 768px) {
    background-color: white;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px;

  @media only screen and (max-width: 768px) {
    padding: 80px 40px 0;
  }
`;

const Logo = styled.img`
  height: 32px;
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 80px;

  @media only screen and (max-width: 768px) {
    justify-content: flex-start;
  }
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: white;
  border: 1px solid ${(props) => props.theme.colors.border};
  padding: 40px;
  max-width: 600px;
  width: 100%;

  h1 {
    width: 100%;
    margin-bottom: 40px;
  }

  @media only screen and (max-width: 768px) {
    border: none;
    text-align: left;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100px;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border: 1px solid #dadce0;
    border-radius: 5px;
    height: 40px;
    width: 300px;
    color: #3c4043;
    font-size: 14px;
    font-weight: regular;
    letter-spacing: 0.25px;
    margin-top: 10px;
    padding: 0 12px;
    transition: background-color 0.218s, border-color 0.218s;

    &:last-child {
      margin-bottom: 0;
    }

    svg {
      font-size: 19px;
      margin-right: 6px;
      margin-top: -1px;
    }

    &:hover {
      border-color: #d2e3fc;
      background-color: rgba(66, 133, 244, 0.04);
    }

    &:disabled {
      color: ${(props) => props.theme.textColors.secondary};

      svg {
        opacity: 0.6;
      }
    }
  }
`;

const Legal = styled.div`
  text-align: center;
  opacity: 0.7;
  max-width: 400px;
  padding-top: 20px;

  p {
    font-size: 10px;
    line-height: 1.5;
    color: ${(props) => props.theme.textColors.secondary};

    a {
      text-decoration: underline;
      color: inherit;
    }
  }

  @media only screen and (max-width: 768px) {
    max-width: 290px;
    text-align: left;

    p {
      font-size: 11px;
      line-height: 1.6;
    }
  }
`;

const Error = styled.p`
  color: ${(props) => props.theme.colors.warning};
  font-size: 13px;
  font-weight: ${(props) => props.theme.fontWeights.medium};
  line-height: 1.4;
  margin-top: 30px;
  margin-bottom: 0;
`;

export default LoginPage;
