import React, { memo } from 'react';
import styled from 'styled-components';

const GridClipMobile = ({ config, onClipClick, onMenuClick }) => (
  <Wrapper>
    <Container
      onClick={() => onClipClick(config)}
      onContextMenu={(e) => onMenuClick(e, config)}>
      <Content>
        <Thumbnail bgImage={config?.preview} />
        <Overlay />
        <Duration>{Math.ceil(config?.duration)} SEC</Duration>
      </Content>
      <Info>
        <TitleBtn>{config?.title}</TitleBtn>
      </Info>
    </Container>
  </Wrapper>
);

const Overlay = styled.div`
  position: absolute;
  display: none;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  background: radial-gradient(
    ellipse at center,
    rgba(0, 0, 0, 0.25) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );
`;

const Wrapper = styled.div`
  @media only screen and (max-width: 768px) {
    margin: 0.25rem;
  }
`;

const Container = styled.div`
  position: relative;
  display: block;
  opacity: 1;
  border-radius: 2px;
  width: 100%;
  max-width: 390px;
  background-color: white;
  box-shadow: 0 1px 4px rgba(85, 116, 162, 0.2);
  transition: all 0.2s ease-in-out;
`;

const Content = styled.div`
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 2px 2px 0 0;
  padding-bottom: 56.25%;
  background-color: #444;
`;

const Duration = styled.div`
  position: absolute;
  bottom: 0.5rem;
  left: 0.5rem;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 4px;
  font-size: 11px;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 0.5px;
  color: white;
  text-transform: uppercase;
  pointer-events: none;
  user-select: none;

  @media only screen and (max-width: 768px) {
    left: auto;
    right: 0.5rem;
  }
`;

const Thumbnail = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
  background-image: url(${(props) => props.bgImage});

  ${({ blurred }) =>
    blurred &&
    `
    filter: blur(20px);
  `}
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  width: 100%;
  padding: 0.5rem 0.75rem;

  @media only screen and (max-width: 768px) {
    padding: 0.25rem 0.5rem;
  }
`;

const TitleBtn = styled.div`
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 0.25rem;
  overflow: hidden;
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  color: ${(props) => props.theme.textColors.primary};

  &:hover {
    color: ${(props) => props.theme.colors.primary};
  }

  @media only screen and (max-width: 768px) {
    font-size: 14px;
    margin-bottom: 0;
  }
`;

export default memo(GridClipMobile);
