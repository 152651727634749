// @flow
import React from 'react';
import styled from 'styled-components';
import { FaPlus } from 'react-icons/fa';
import { MdPlaylistPlay } from 'react-icons/md';

const PlaylistThumbnail = ({ images, create, size = 'regular' }) => (
  <Wrapper size={size}>
    {create ? (
      <FaPlus />
    ) : images?.length > 0 ? (
      <Content>
        {images.length > 2 ? (
          <TileWrapper>
            {images.map((img, idx) =>
              idx < 4 ? (
                <Tile key={img}>
                  <Image src={img} />
                </Tile>
              ) : null,
            )}
          </TileWrapper>
        ) : (
          <Image src={images[0]} />
        )}
      </Content>
    ) : (
      <DefaultIcon>
        <MdPlaylistPlay />
      </DefaultIcon>
    )}
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: ${(props) => props.theme.backgroundColors.secondary};
  width: 64px;
  height: 64px;
  border-radius: 4px;

  box-shadow: inset 0 0 0 1px
    rgba(${(props) => props.theme.colors.borderRGB}, 0.6);

  svg {
    font-size: 28px;
    color: ${(props) => props.theme.textColors.primary};
  }

  ${({ size }) =>
    size === 'large' &&
    `
		width: 120px;
		height: 120px;

		svg {
			font-size: 52px;
			line-height: 1;
		}
	`}

  ${({ size }) =>
    size === 'small' &&
    `
		width: 40px;
		height: 40px;

		svg {
			font-size: 14px;
		}
	`}
`;

const DefaultIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  > svg {
    color: ${(props) => props.theme.colors.border};
  }
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
`;

const TileWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
`;

const Tile = styled.div`
  width: 50%;
  height: 50%;
`;

const Image = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export default PlaylistThumbnail;
