import * as amplitude from '@amplitude/analytics-browser';

const useAmplitude = () => {
  const trackEvent = (eventName, properties) => {
    amplitude.track(eventName, { Client: 'Web', ...properties });
  };

  const setUserProperty = (key, value) => {
    const identify = new amplitude.Identify();
    identify.set(key, value);
    amplitude.identify(identify);
  };

  const trackRegisteredUser = (authType, user) => {
    try {
      amplitude.track('User Registered', { 'Registered Using': authType });
      amplitude.setUserId(user?.id);

      const identify = new amplitude.Identify();
      identify
        .set('First Name', user?.firstName)
        .set('Last Name', user?.lastName)
        .set('Email', user?.email)
        .set('Clips Created', 0)
        .set('Playlists Created', 0)
        .set('Pro Subscription', 'None');
      amplitude.identify(identify);
    } catch (error) {
      console.log('trackRegisteredUser Error: ', error);
    }
  };

  const trackLogin = async (authType, user) => {
    try {
      amplitude.track('User Logged In', { 'Registered Using': authType });
      amplitude.setUserId(user?.id);
      const identify = new amplitude.Identify();
      identify
        .set('First Name', user?.firstName)
        .set('Last Name', user?.lastName)
        .set('Email', user?.email);
      amplitude.identify(identify);
    } catch (error) {
      console.log('trackLogin Error: ', error);
    }
  };

  return { trackEvent, trackLogin, trackRegisteredUser, setUserProperty };
};

export default useAmplitude;
