import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { darken } from 'polished';
import { MdClose } from 'react-icons/md';
import { FaPlus } from 'react-icons/fa';
import PredictiveTagsRow from './PredictiveTagsRow';
import {
  reduceToTagFormat,
  findObjectInArray,
} from '../../hooks/useTextHelper';

const MINIMUM_TAG_LENGTH = 3;

const TagsInput = ({ tags, onTagsUpdate }) => {
  const tagRef = useRef(null);
  const commaCleared = useRef(false);

  const [predictiveTags, setPredictiveTags] = useState();
  const [tagInput, setTagInput] = useState('');
  const [invalid, setInvalid] = useState(false);

  const tagKeyEntered = (e) => {
    if ([',', 'Enter'].includes(e.key)) {
      if (e.key === ',') commaCleared.current = true;
      addTag();
    }
  };

  const addTag = () => {
    if (tagInput.length < MINIMUM_TAG_LENGTH) return;
    const newTag = {
      tag: reduceToTagFormat(tagInput),
      tagLabel: tagInput,
    };
    addTagToList(newTag);
  };

  const addTagToList = async (newTag) => {
    const isDuplicate = findObjectInArray(tags, 'tag', newTag.tag);
    if (isDuplicate) {
      setInvalid(true);
      return;
    }

    const existingTag = null; // await checkIfTagExists(newTag.tag);

    const tagToAdd = existingTag || newTag;

    onTagsUpdate([...tags, tagToAdd]);
    setTagInput('');

    tagRef.current.focus();
  };

  const deleteTag = (index) => {
    const _tags = [...tags];
    _tags.splice(index, 1);
    onTagsUpdate(_tags);
  };

  const runPredictiveSearch = async (txt) => {
    if (commaCleared.current) {
      commaCleared.current = false;
      return;
    }
    if (invalid) setInvalid(false);
    setTagInput(txt);

    if (txt.length < 2) {
      setPredictiveTags(null);
      return;
    }

    // const tag = reduceToTagFormat(txt);
    // const results = await searchTags(txt);
    const results = [];
    setPredictiveTags(results);
  };

  return (
    <Container>
      <InputRow>
        <TextInput
          ref={tagRef}
          isInvalid={invalid}
          value={tagInput}
          onChange={(e) => runPredictiveSearch(e.target.value)}
          onKeyDown={tagKeyEntered}
          placeholder="Enter tag"
          maxLength={100}
        />
        <AddBtn onClick={addTag}>
          <FaPlus />
        </AddBtn>
      </InputRow>
      <PredictiveTagsRow
        tags={predictiveTags}
        restricted={tags}
        onTagClick={addTagToList}
      />
      <TagsScroll>
        <TagsWrapper>
          {tags.length > 0
            ? tags.map(({ tag }, index) => (
                <TagBlock key={`tag-${index}`}>
                  <TagBtn>
                    <span>{`#${tag}`}</span>
                    <RemoveBtn onClick={() => deleteTag(index)}>
                      <MdClose />
                    </RemoveBtn>
                  </TagBtn>
                </TagBlock>
              ))
            : null}
        </TagsWrapper>
      </TagsScroll>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-self: flex-end;
  overflow: hidden;
`;

const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  border: 1px solid ${(props) => props.theme.colors.border};
  border-radius: 7px;
`;

const TextInput = styled.input`
  flex: 1;
  background: transparent;
  color: ${(props) =>
    props.isInvalid
      ? props.theme.colors.secondary
      : props.theme.textColors.primary};
  font-size: 15px;
  font-weight: 500;
  background: none;
  border: 0;
  height: 45px;
  padding: 0 12px;
  pointer-events: auto;
`;

const AddBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 1px solid ${(props) => props.theme.colors.border};
  border-radius: 40px;
  width: 30px;
  height: 30px;
  margin-left: 10px;
  pointer-events: all;
  margin: 0 6px;

  svg {
    color: ${(props) => props.theme.textColors.secondary};
  }
`;

const TagsScroll = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: scroll;
  pointer-events: all;
  height: 120px;
  max-height: 120px;
`;

const TagsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px 0;
`;

const TagBlock = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TagBtn = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 8px 6px 10px;
  background-color: white;
  outline: none;
  background-color: ${(props) => darken(0.02, props.theme.colors.border)};
  border-radius: 34px;
  margin: 3px;
  color: ${(props) => props.theme.textColors.primary};
  line-height: 1;
  font-family: ${(props) => props.theme.fonts.primary};
  font-size: 13px;
  pointer-events: all;
`;

const RemoveBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 3px;
  cursor: pointer;
  svg {
    color: ${(props) => props.theme.textColors.primary};
    font-size: 15px;
  }

  &:hover {
    opacity: 0.8;
  }
`;

export default TagsInput;
