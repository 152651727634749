import React from 'react';
import ReactDOM from 'react-dom';
import Shell from './App/Shell';
import Session from './App/Session';
import Root from './App/Root';
import Theme from './App/Theme';
import GlobalStyle from './globalStyles';

ReactDOM.render(
  <React.StrictMode>
    <Theme>
      <GlobalStyle />
      <Root>
        <Session>
          <Shell />
        </Session>
      </Root>
    </Theme>
  </React.StrictMode>,
  document.getElementById('root'),
);
