import React, { memo } from 'react';
import styled from 'styled-components';
import { MdAddCircleOutline } from 'react-icons/md';
import PlaylistThumbnail from './PlaylistThumbnail';
import usePlaylistActions from '../../hooks/usePlaylistActions';
import StarButton from './StarButton';

const PlaylistItem = ({ config, mode, starred, onClick }) => {
  const { starPlaylist } = usePlaylistActions();

  const onStar = (e) => {
    e.stopPropagation();
    starPlaylist(config._id.toString(), !starred);
  };

  return (
    <Wrapper mode={mode} onClick={() => onClick(config._id)}>
      <PlaylistThumbnail
        size={mode === 'add' ? 'small' : 'regular'}
        images={config?.image}
      />
      <TextWrapper>
        <PlaylistTitle>{config?.title}</PlaylistTitle>
        <ClipCount>{`${config?.count} clip${
          config?.count === 1 ? '' : 's'
        }`}</ClipCount>
      </TextWrapper>
      {mode === 'add' ? (
        <AddBtn>
          <MdAddCircleOutline />
        </AddBtn>
      ) : (
        <StarButtonWrapper>
          <StarButton starred={starred} onClick={onStar} />
        </StarButtonWrapper>
      )}
    </Wrapper>
  );
};

const TextWrapper = styled.div`
  flex: 1;
  padding-left: 14px;
`;

const PlaylistTitle = styled.div`
  font-size: 15px;
  font-weight: ${(props) => props.theme.fontWeights.medium};
  color: ${(props) => props.theme.textColors.primary};
  margin-bottom: 3px;
  line-height: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  @media only screen and (max-width: 768px) {
    font-size: 16px;
  }
`;

const ClipCount = styled.div`
  font-size: 14px;
  color: ${(props) => props.theme.textColors.secondary};
  line-height: 1;

  @media only screen and (max-width: 768px) {
    margin-top: 2px;
  }
`;

const AddBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 10px 20px 20px;

  svg {
    font-size: 18px;
    color: ${(props) => props.theme.textColors.secondary};
    opacity: 0.5;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  padding: 3px 15px;

  &:hover,
  &:active {
    background-color: rgba(${(props) => props.theme.colors.borderRGB}, 0.4);

    svg {
      opacity: 1;
    }
  }

  ${({ mode }) =>
    mode !== 'add' &&
    `
      padding: 3px 15px 10px;

      ${TextWrapper} {
        padding-left: 18px;
      }

      ${PlaylistTitle} {
        font-size: 17px;
        margin-bottom: 5px;
      }
  `}
`;

const StarButtonWrapper = styled.div`
  margin-left: auto;
  padding: 0 5px 0 10px;
`;

export default memo(PlaylistItem);
